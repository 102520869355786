import {
  SET_CORE_MERCHANT_VALUE,
  SET_IMAGE_URLS,
  SET_ORDER_ID,
} from "../Actions/mediaDirectoryActions";

const initialState = {
  coreMerchantValue: null,
  backgroundLogo: null,
  logoURL: null,
  orderId: null,
};

const mediaDirectoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CORE_MERCHANT_VALUE:
      return {
        ...state,
        coreMerchantValue: action.payload,
      };

    case SET_IMAGE_URLS:
      return {
        ...state,
        backgroundLogo: action.payload.backgroundLogo,
        logoURL: action.payload.logoURL,
      };

    case SET_ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      };

    default:
      return state;
  }
};

export default mediaDirectoryReducer;
