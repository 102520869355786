import axios from "axios";
const axiosInstance = axios.create({
  // Your base configuration here
  // baseURL:``,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.tenant = `${process.env.REACT_APP_TENANT}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;

    if (error.response && error.response.status === 401) {
      try {
        const token = await refreshToken(localStorage.getItem("refreshToken"));
        config.headers.Authorization = `Bearer ${token}`;
        return axiosInstance(config);
      } catch (refreshError) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const refreshToken = (_refreshToken) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_MEMBER_URL}/api/v1/auth/refresh-token`,
      { RefreshToken: _refreshToken },
      {
        headers: {
          Authorization: `Bearer ${localStorage?.getItem("token")}`,
          tenant: `${process.env.REACT_APP_TENANT}`,
          Client: "backend-services",
        },
      }
    )
    .then((res) => {
      if (res?.data?.data && res?.data?.data?.accessToken) {
        localStorage.setItem("token", res?.data?.data?.accessToken);
      }
      return res?.data?.data?.accessToken;
    })
    .catch((error) => {
      localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    });
};

export default axiosInstance;
