import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBIcon,
  MDBModalHeader,
  MDBTooltip,
} from "mdbreact";
import Back from "../../assets/home/back-arrow.svg";
import Check from "../../assets/home/check-bottom.png";
import HappyFace from "../../assets/home/happy-face.svg";
import Sad from "../../assets/home/sad-face.svg";
import Splitting from "../../assets/home/splitting.png";
import Discard from "../../assets/home/discard.png";
import Bill from "../../assets/home/bill.svg";
import disabledBill from "../../assets/home/grayBill.svg";
import splitByItems from "../../assets/home/splitByItems.svg";
import splitEvenly from "../../assets/home/splitEvenly.svg";
import splitCustom from "../../assets/home/splitCustom.svg";
import BlueArrow from "../../assets/home/blue-arrow.svg";
import ViewMenuImg from "../../assets/home/view-menu-img.svg";
import globe from "../../assets/home/globe.svg";
import invalidQR from "../../assets/home/invalidQR.svg";
import moneyPaper from "../../assets/home/moneyPaper.svg";
import invoiceBill from "../../assets/home/invoiceBill.svg";
import BackEN from "../../assets/home/back-arrow.svg";
import BackAR from "../../assets/home/back-arrow-ar.svg";
import Share from "../../assets/home/share-blue.svg";
import arabicShare from "../../assets/home/arabicShare.svg";
import { withTranslation } from "react-i18next";
import SplitTheBill from "../SplitTheBill/SplitTheBill";
import { FixedHeightFlag } from "use-flags";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import i18n from "../../i18n-config/i18n";
import {
  CONFIG,
  GET_TERMINAL,
  LOGINCONFIG,
  LOGIN_GUEST,
  PAY_NOW,
  SCAN_INFO,
  UPDATE_TIP,
  HAS_OPEN_INVOICE,
  GET_API_URL,
  TRANSACTION_LOCKED,
  GET_FILE_DIRCTORY,
} from "../../APIs/index";
import Loader from "./Loader.js";
import axiosInstance from "../../APIs/axios.jsx";
import Axios from "axios";
import ModalComponent from "../../Components/Modal/ModalComponent.jsx";
import { setTokens } from "../../Redux/Actions/authActions.js";
import {
  setCoreMerchantValue,
  setImageUrls,
  setOrderId,
} from "../../Redux/Actions/mediaDirectoryActions.js";
import { removeQueryParameter } from "../../UTILs/functions.js";

const queryString = window.location.search;

const urlParams = new URLSearchParams(queryString);

class HomePage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      // isMobile: window.innerWidth <= 435,
      isTablet: matchMedia("(max-width: 1024px)").matches,
      isMobile: matchMedia("(max-width: 435px)").matches,
      payBill: false,
      unavailableSystem: false,
      wrongQR: false,
      showInput: false,
      other: null,
      recordGuid: urlParams.get("RecordGuid")
        ? urlParams.get("RecordGuid")
        : urlParams.get("recordGuid")
        ? urlParams.get("recordGuid")
        : "",
      dataInfo: {},
      totalBill: 500,
      tip: 0,
      viewMenu: false,
      invoiceLinked: false,
      errors: "",
      loading: true,
      languageModal: false,
      countries: [],
      selectedOption: "",
      splitModal: false,
      splitOption: "",
      showSplittingData: false,
      exceededAmount: false,
      payingTheBillModal: false,
      refreshModal: false,
      splitTheBillItems: [],
      show: true,
      amountLeft: "",
      splittingModal: false,
      discardModal: false,
      paymentCompleted: false,
      getMyReceipt: false,
      isHovered: false,
      isHoveredReceipt: false,
      isHoveredDownload: false,
      isHoveredEmail: false,
      token: "",
      res: {},
      visitorId: "",
      totalWithVat: 0,
      ImageLinks: [],
      backgroundLogo: null,
      logoUrl: null,
      promotionalBtns: [],
      tenPercentTip: 0,
      fiftenPercentTip: 0,
      twentyPercentTip: 0,
      qrValue: null,
      viewTip: false,
      active: 1,
      hasOpenInvoice: false,
      loadingHasOpenInvoice: true,
      wrongBranchModal: false,
      displayLoader: false,
      modalTitle: "",
      showFullyButton: false,
      showPartiallyButton: false,
      showPartiallyItemsButton: false,
      showPartiallyEvenlyButton: false,
      showPartiallyAmountButton: false,
      backgroundImage: null,
      logoImage: null,
      underDevelopmentModal: false,
    };
  }

  handleTabletResize = () => {
    this.setState({ isTablet: window.innerWidth <= 1024 });
  };

  handleMobileResize = () => {
    this.setState({ isMobile: window.innerWidth <= 435 });
  };

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();

    this.fetchVisitorId();
    this.tabletMedia = matchMedia("(max-width: 1024px)");
    this.mobileMedia = matchMedia("(max-width: 435px)");
    this.tabletMedia.addEventListener("change", this.setTabletMedia);
    this.mobileMedia.addEventListener("change", this.setMobileMedia);
  }

  componentWillUnmount() {
    this.tabletMedia.removeEventListener("change", this.setTabletMedia);
    this.mobileMedia.removeEventListener("change", this.setMobileMedia);
  }

  setTabletMedia = ({ matches }) => this.setState({ isTablet: matches });
  setMobileMedia = ({ matches }) => this.setState({ isMobile: matches });

  fetchVisitorId = async () => {
    try {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const visitorId = result.visitorId + "v_1";
      this.setState({ visitorId: visitorId });
      this.getMediaDirectory();
      this.loginGuest();
    } catch (error) {
      // console.error('Error fetching visitor ID:', error);
    }
  };

  getMediaDirectory = async () => {
    try {
      const res = await Axios.get(`${GET_FILE_DIRCTORY}`, {
        ...LOGINCONFIG,
        headers: {
          ...LOGINCONFIG.headers,
          tenant: `${process.env.REACT_APP_TENANT}`,
        },
      });
      const languageResponse = res?.data?.data?.paths || {};
      const coreMerchantValue = languageResponse["montypay-core-qcheck"];
      this.props.setCoreMerchantValue(coreMerchantValue);
    } catch (err) {
    } finally {
      this.setState({ loading: false });
    }
  };

  loginGuest = async () => {
    this.setState({ loading: true, loadingHasOpenInvoice: true });

    try {
      const res = await Axios.post(
        `${LOGIN_GUEST}`,
        {
          Identifier: this.state.visitorId,
        },
        {
          ...LOGINCONFIG,
          headers: {
            ...LOGINCONFIG.headers,
            tenant: `${process.env.REACT_APP_TENANT}`,
            Client: "backend-services",
          },
        }
      );
      const { data } = res.data;
      this.props.setTokens(data?.accessToken, data?.refreshToken);
      this.setState({
        token: data.accessToken,
        loadingHasOpenInvoice: false,
      });

      if (data.accessToken) {
        await this.getTerminal(data.accessToken);
        await this.hasOpenInvoice(data?.accessToken);
      }
    } catch (err) {
      if (err === "error: 401") {
        this.loginGuest();
      } else if (err?.response?.data?.message === "Login guest error") {
        this.setState({ unavailableSystem: true });
      } else if (err?.response === undefined) {
        this.setState({ wrongBranchModal: true, errors: err?.response });
      } else {
        this.setState({ errors: err?.response?.data?.message });
      }
    } finally {
      this.setState({
        loading: false,
        loadingHasOpenInvoice: false,
        errors: "",
      });
    }
  };

  getTerminal = async (token) => {
    const { recordGuid } = this.state;
    LOGINCONFIG.headers.Authorization = `Bearer ${token}`;
    LOGINCONFIG.headers["LanguageCode"] = i18n.language || "en";
    try {
      const res = await axiosInstance.get(
        `${GET_TERMINAL}?RecordGuid=${recordGuid}`,
        LOGINCONFIG
      );
      let data = res?.data?.data;

      // this.displayPaymentButtons(data?.item?.splitPaymentOptions);
      const backgroundLogo =
        data?.item?.branch?.details[0]?.backgroundIdentifier;

      const logoUrl = data?.item?.branch?.details[0]?.logoIdentifier;

      // Set the image URLs in the Redux store
      this.props.setImageUrls(backgroundLogo, logoUrl);
      const isDefault = data?.item?.languages.find((x) => x.isDefault === true);
      this.setState({
        invoiceLinked: data?.item?.hasOpenInvoice,
        languages: data?.item?.languages,
        ImageDetails: data?.item?.branch?.details,
        backgroundImage: data?.item?.branch?.details[0]?.backgroundIdentifier,
        logoImage: data?.item?.branch?.details[0]?.logoIdentifier,
        menuURL: data?.item?.links[1]?.details[0]?.url,
        promotionalBtns: data?.item?.links.find((x) => x.typeTag === "BUTTON"),
        languageCode: isDefault?.code,
        displayLoader: false,
      });

      i18n.changeLanguage(isDefault?.code);
    } catch (err) {
      if (err === "error: 401") {
        this.getTerminal(token);
      }
      if (
        err?.response?.data?.message ===
        "sorry no invoice pending or fail to pay under this terminal please create new invoice"
      ) {
        this.setState({ unavailableSystem: true });
      }
      if (err?.response?.status === 400) {
        this.toggleWrongQRModal();
      }
      this.setState({ errors: err?.response?.data?.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  // getMediaLink = async (path, identifier) => {
  //   const res = await axiosInstance.get(
  //     `${GET_MEDIA_LINK}${path}/${identifier}`
  //   );
  //   return res.config.url;
  // };

  displayPaymentButtons = (splitPaymentOptions) => {
    const showFullyButton = splitPaymentOptions.some(
      (option) => option.name && option.name.toLowerCase() === "fully"
    );
    const showPartiallyButton = splitPaymentOptions.some(
      (option) => option.name && option.name.toLowerCase().includes("partially")
    );
    const showPartiallyItemsButton = splitPaymentOptions.some(
      (option) => option.name && option.name.toLowerCase() === "partially items"
    );
    const showPartiallyAmntButton = splitPaymentOptions.some(
      (option) =>
        option.name && option.name.toLowerCase() === "partially amount"
    );
    const showPartiallyEvenlyButton = splitPaymentOptions.some(
      (option) =>
        option.name && option.name.toLowerCase() === "partially evenly"
    );

    this.setState({
      showFullyButton,
      showPartiallyItemsButton,
      showPartiallyAmountButton: showPartiallyAmntButton,
      showPartiallyEvenlyButton,
      showPartiallyButton,
    });
  };

  hasOpenInvoice = async (token) => {
    const { recordGuid, languageCode } = this.state;
    this.setState({
      loading: true,
      disabled: true,
      loadingHasOpenInvoice: true,
    });
    LOGINCONFIG.headers.Authorization = `Bearer ${token}`;
    LOGINCONFIG.headers["LanguageCode"] = i18n.language || languageCode;

    await axiosInstance
      .get(`${HAS_OPEN_INVOICE}?RecordGuid=${recordGuid}`, LOGINCONFIG)
      .then((res) => {
        if (res?.data?.message === "Success") {
          this.setState({
            hasOpenInvoice: res?.data?.data?.hasOpenInvoice,
            loadingHasOpenInvoice: false,
          });
        } else {
          this.setState({
            hasOpenInvoice: false,
            loadingHasOpenInvoice: false,
          });
        }
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.hasOpenInvoice(token);
        }
        this.setState({
          loading: false,
          disabled: false,
          loadingHasOpenInvoice: false,
        });
        if (err?.response?.status === 400) {
          this.setState({
            loading: false,
            disabled: false,
            message: err?.response?.data?.message,
            loadingHasOpenInvoice: false,
            payingTheBillModal: false,
          });
        }
      });
  };

  checkTransactionLock = async (token) => {
    const { t } = this.props;
    const { recordGuid, languageCode, amountLeft, dataInfo } = this.state;
    const checkAmountPaid = this.roundNumber(
      dataInfo.totalAmountWithVat - amountLeft
    );
    LOGINCONFIG.headers.Authorization = `Bearer ${token}`;
    LOGINCONFIG.headers["LanguageCode"] = languageCode;

    await axiosInstance
      .get(`${TRANSACTION_LOCKED}?Reference=${recordGuid}`, LOGINCONFIG)
      .then((res) => {
        this.setState({ hasOpenInvoice: false });
        if (res?.data?.message === "Success") {
          if (res?.data?.data?.isThereOpenSession === false) {
            const amountPaidFromServer = this.roundNumber(
              res?.data?.data?.amountPaid
            );
            if (
              this.roundNumber(Number(checkAmountPaid)).toFixed(2) ===
              this.roundNumber(Number(amountPaidFromServer).toFixed(2))
            ) {
              this.setState(
                {
                  amountPaid: amountPaidFromServer,
                  checkAmountPaid: checkAmountPaid,
                },
                () => this.payNow()
              );
            } else {
              this.setState(
                {
                  amountPaid: amountPaidFromServer,
                  checkAmountPaid: checkAmountPaid,
                },
                () =>
                  this.toggleRefreshModal(checkAmountPaid, amountPaidFromServer)
              );
            }
          } else if (res?.data?.data?.isThereOpenSession === true) {
            this.setState(
              { message: t("Your table mate is already paying the bill") },
              () => this.toggleSplittingTheModal()
            );
          }
        }
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.checkTransactionLock(token);
        }

        this.setState({
          loading: false,
          disabled: false,
          loadingHasOpenInvoice: false,
        });
        if (err?.response?.status === 400) {
          this.setState({
            loading: false,
            disabled: false,
            message: err?.response?.data?.message,
            loadingHasOpenInvoice: false,
            payingTheBillModal: false,
          });
        }
        console.error(
          err?.response ? err?.response?.data?.result?.message : err
        );
      });
  };

  updateAmountLeftToPay = () => {
    const { amountPaid, dataInfo } = this.state;
    let updatedAmountLeftToPay = this.roundNumber(
      dataInfo.totalAmountWithVat - amountPaid
    );
    this.setState({ amountLeft: updatedAmountLeftToPay }, () =>
      this.toggleRefreshModal()
    );
  };

  handleResize = () => {
    this.updateIsMobileState(); // Call method to update isMobile state
  };

  updateIsMobileState = () => {
    const isMobile = window.innerWidth <= 435; // Adjust the width breakpoint as needed
    this.setState({ isMobile });
  };

  getScanInfo = async (token) => {
    const { tip, languageCode, recordGuid, splitOption } = this.state;
    this.setState({
      loading: true,
      loadingHasOpenInvoice: true,
      errors: false,
      hasOpenInvoice: false,
    });
    LOGINCONFIG.headers.Authorization = `Bearer ${token}`;
    LOGINCONFIG.headers["LanguageCode"] = i18n.language || languageCode;
    axiosInstance
      .get(`${SCAN_INFO}?RecordGuid=${recordGuid}`, LOGINCONFIG)
      .then((res) => {
        let { data } = res?.data;
        if (res?.data?.success) {
          if (splitOption === "") {
            this.setState({
              loadingHasOpenInvoice: false,
              errors: false,
              hasOpenInvoice: true,
              dataInfo: data,
              totalWithVat: data?.totalAmountWithVat,
              // invoiceLinked: data?.hasOpenInvoice,
              // tip: data?.tip,
              totalAmount: data?.totalAmount + data?.vat,
              totalBill: data?.totalAmount,
              vat: data?.vat,
              invoiceId: data?.id,
              currency: data?.currency,
              // splitEnabled: data?.isSplitted,
              isAcquired: data?.isAcquired,
              payingTheBillModal: data?.isAcquired === true ? true : false,
              languages: data?.branch?.languages,
              splitTheBillItems: data?.items,
              amountLeft:
                data?.amountLeftToPay === 0
                  ? data?.totalAmountWithVat
                  : data?.amountLeftToPay,
              amountToPay:
                data?.amountLeftToPay === 0
                  ? parseFloat(data?.totalAmountWithVat + tip)
                  : data?.amountLeftToPay,
              disablePayFully: data?.disablePayFully,
              tenPercentTip: this.roundNumber(
                data?.totalAmountWithVat * (10 / 100)
              ),
              fiftenPercentTip: this.roundNumber(
                data?.totalAmountWithVat * (15 / 100)
              ),
              twentyPercentTip: this.roundNumber(
                data?.totalAmountWithVat * (20 / 100)
              ),
              qrValue: data?.loyaltyQrImage || null,
            });

            setTimeout(() => {
              this.setState({ payBill: !this.state.payBill, errors: false });
            }, 500);
            this.displayPaymentButtons(data?.splitPaymentOptions);
          } else {
            this.setState({
              dataInfo: data,
              showSplittingData: true,
              displayLoader: false,
              loadingHasOpenInvoice: true,
              loading: true,
              hasOpenInvoice: false,
            });
          }
        }
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.getScanInfo(token);
        }
        if (err?.response?.status === 400) {
          this.setState(
            {
              payBill: false,
              loadingHasOpenInvoice: false,
              message: err?.response?.message,
            },
            () => this.toggleWrongQRModal()
          );
          // this.setState({ unavailableSystem: true })
          return true;
        }
        if (err?.response?.status === 500) {
          this.setState(
            {
              payBill: false,
              loadingHasOpenInvoice: false,
              loading: false,
              noExternalIdMsg:
                err?.response?.data?.Exception || err?.response?.data?.message,
            },
            () => this.toggleWrongBranchModal()
          );
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  toggleWrongBranchModal = () => {
    const { errors } = this.state;
    if (errors === undefined) {
      this.setState({ wrongBranchModal: !this.state.wrongBranchModal }, () =>
        this.loginGuest()
      );
    } else {
      this.setState({ wrongBranchModal: !this.state.wrongBranchModal });
    }
  };

  openViewTip = () => {
    this.setState({ viewTip: true }, () => this.handleTipChange(10));
  };

  payNow = async () => {
    this.setState({ loading: true, disabledPayFully: true });
    LOGINCONFIG.headers.Authorization = `Bearer ${this.state.token}`;
    const currentUrl = window.location.href;
    const updatedUrl = removeQueryParameter(currentUrl, "RecordGuid");
    const cleanedUrl = updatedUrl.split("?")[0];
    const successUrl = `${cleanedUrl}success:externalReferenceId=${this.state.recordGuid}`;
    const failedUrl = `${cleanedUrl}failed:externalReferenceId=${this.state.recordGuid}`;

    let body = {
      TipAmount: this.state.tip,
      TerminalGuid: this.state.recordGuid,
      SuccessUrl: successUrl,
      FailedUrl: failedUrl,
    };

    await axiosInstance
      .post(`${UPDATE_TIP}`, body, LOGINCONFIG)
      .then((res) => {
        if (res?.data?.success) {
          this.setState({ loading: false, disabledPayFully: true });
          const redirectionURL = res?.data?.data?.redirectionURL;
          this.props.setOrderId(res?.data?.data?.orderId);

          // Check if the user is on a mobile device
          const isMobile = /Mobi|Android/i.test(navigator.userAgent);
          if (isMobile) {
            const newWindow = window.open(redirectionURL, "_blank");
            if (!newWindow) {
              window.location.href = redirectionURL;
            }
          } else {
            window.open(redirectionURL, "_blank");
          }
        }
      })
      .catch((err) => {
        this.handlePayNowErrors(err);
      });
  };

  // Function to handle errors
  handlePayNowErrors = (err) => {
    if (err === "error: 401" || err?.data?.Status === 401) {
      this.payNow();
    }
    if (err?.response?.status === 409 || err?.response?.status === 500) {
      this.setState({
        loading: false,
        disabledPayFully: false,
        message: err?.response?.data?.message || err?.response?.data?.Exception,
        payingTheBillModal: true,
      });
    } else if (err?.response?.status === 423) {
      this.setState({
        loading: false,
        disabledPayFully: false,
        message: err?.response?.data?.message,
        splittingModal: true,
      });
    } else if (err?.response?.status === 400) {
      this.setState({
        loading: false,
        disabledPayFully: false,
        message: err?.response?.data?.message,
      });
      localStorage.setItem("paymentCompleted", true);
      this.props.history.push(
        `success:externalReferenceId=${this.state.recordGuid}`
      );
    }
  };

  redirectToPaymentGateway = () => {
    CONFIG.headers.Authorization = "bearer " + this.props.token;
    axiosInstance
      .post(`${PAY_NOW}/${this.state.dataInfo?.id}/pay`, {}, CONFIG)
      .then((res) => {
        if (res?.data?.data?.paymentUrl !== "") {
          this.setState({ loading: false });

          // Open the redirection URL in a new window or tab
          const redirectionURL = res?.data?.data?.paymentUrl;

          // Check if the user is on a mobile device
          const isMobile = /Mobi|Android/i.test(navigator.userAgent);

          if (isMobile) {
            // On mobile, try opening the URL in a new window
            const newWindow = window.open(redirectionURL, "_blank");
            if (!newWindow) {
              // If opening in a new window fails, fallback to changing the current location
              window.location.href = redirectionURL;
            }
          } else {
            // On desktop, open the URL in a new tab
            window.open(redirectionURL, "_blank");
          }
        }
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.redirectToPaymentGateway();
        }
        console.error(
          err?.response ? err?.response?.data?.result?.message : err
        );
      });
  };

  togglePayBill = () => {
    this.getScanInfo(this.state.token);
    // this.checkTransactionLock(this.state.token);
    // setTimeout(() => {
    //   this.setState({ payBill: !this.state.payBill });
    // }, 500);
  };

  toggleViewMenu = () => {
    this.setState({ viewMenu: !this.state.viewMenu });
  };

  toggleSuccessModal = () => {
    this.setState({ successModal: !this.state.successModal });
  };

  toggleunavailableSystemModal = () => {
    this.setState({ unavailableSystem: !this.state.unavailableSystem });
  };

  toggleSplittingTheModal = () => {
    this.setState({
      splittingModal: !this.state.splittingModal,
      modalTitle: "Splitting The Bill",
    });
  };

  closeSplittingTheModal = () => {
    this.setState({
      splittingModal: false,
      modalTitle: "",
    });
  };

  toggleDiscardTheModal = () => {
    this.setState({
      discardModal: !this.state.discardModal,
    });
  };

  closeDiscardModal = () => {
    this.setState({
      discardModal: false,
      showSplittingData: false,
      payBill: false,
      loading: false,
      loadingHasOpenInvoice: false,
      splitOption: "",
    });
  };

  toggleWrongQRModal = () => {
    this.setState({
      wrongQR: !this.state.wrongQR,
      modalTitle: "Sorry!",
      message: "QR Code is not valid",
    });
  };

  closeWrongQRModal = () => {
    this.setState(
      {
        wrongQR: false,
        modalTitle: "",
        message: "",
      },
      () => this.loginGuest()
    );
  };

  toggleShowInput = () => {
    this.setState({ showInput: !this.state.showInput, active: "" }, () => {
      document.getElementById("inputRef").focus();
    });
  };

  handleTipChange = (value) => {
    const { dataInfo, amountLeft } = this.state;

    let total = 0;
    dataInfo.items.forEach((item) => {
      total += item.itemPrice * item.quantity;
    });
    const totalWithoutTip = total + dataInfo?.vat;

    const yourShareFloat = parseFloat(amountLeft);
    const tipFloat = parseFloat(
      this.roundNumber(yourShareFloat * (value / 100))
    );

    const amountToPay = yourShareFloat + tipFloat;

    this.setState({
      active: value === 10 ? 1 : value === 15 ? 2 : 3,
      showInput: false,
      // tip: this.roundNumber((total + dataInfo?.vat) * (value / 100)),
      tip: this.roundNumber(amountLeft * (value / 100)),
      tenPercentTip: this.roundNumber(amountLeft * (10 / 100)),
      fiftenPercentTip: this.roundNumber(amountLeft * (15 / 100)),
      twentyPercentTip: this.roundNumber(amountLeft * (20 / 100)),
      other: null,
      totalAmount: totalWithoutTip + totalWithoutTip * (value / 100),
      totalWithoutTip: total,
      amountToPay: amountToPay,
    });
  };

  roundNumber = (number) => {
    if (this.isDecimal(number)) {
      return number.toFixed(2);
    } else {
      return number;
    }
  };

  isDecimal(number) {
    return number % 1 !== 0;
  }

  updateAmount = () => {
    const { other, dataInfo, amountLeft, tip } = this.state;
    let total = 0;
    dataInfo.items.forEach((item) => {
      total += item.totalPrice * item.quantity;
    });
    if (other === "") {
      this.setState({
        amountToPay: parseFloat(amountLeft + tip),
        totalAmount: parseFloat(this.state.amountInput + other),
        tip: 0,
      });
      // this.setState({ totalAmount: totalWithVat });
    } else {
      this.setState({
        amountToPay:
          this.state.splitOption === 3
            ? parseFloat(this.state.amountInput) + other === ""
              ? 0 + tip
              : parseInt(other) + parseFloat(tip)
            : parseFloat(amountLeft) + parseFloat(tip),
        totalAmount:
          this.state.splitOption === 3
            ? parseFloat(this.state.amountInput) + parseInt(other)
            : total + dataInfo?.vat + parseInt(other),
      });
      // this.setState({ totalAmount: totalWithVat + parseFloat(other) });
    }
  };

  getTotalWithoutTip = () => {
    const { dataInfo } = this.state;
    let total = 0;
    dataInfo.items.forEach((item) => {
      total += item.totalPrice * item.quantity;
    });
    const totalWithoutTip = total + dataInfo?.vat;

    // this.setState({ totalAmount: totalWithoutTip })
  };

  toggleLanguageModal = () => {
    this.setState({ languageModal: !this.state.languageModal }, () =>
      this.getFlags()
    );
  };

  getFlags = async () => {
    await axiosInstance
      .get("https://restcountries.com/v2/all")
      .then((data) => {
        this.setState({ countries: data.data });
      })
      .catch((err) => {
        this.setState({ errors: err?.response?.data?.message });
      })

      .finally(() => this.setState({ loading: false }));
  };

  selectFilter = (value, index) => {
    this.setState({
      selectedRadio: index,
      languageCode: value === "Arabic" ? "ar" : "en",
    });
    i18n.changeLanguage(value === "Arabic" ? "ar" : "en");
  };

  toggleSplitModal = () => {
    this.setState({ splitModal: !this.state.splitModal, other: null });
  };

  openSplitFeature = async (value) => {
    this.setState(
      {
        displayLoader: true,
        splitOption: value,
        splitModal: false,
        showSplittingData: true,
      },
      () => this.getScanInfo(this.state.token)
    );
    // this.getScanInfo(this.state.token);

    // this.setState({
    //   showSplittingData: true,
    //   splitOption: value,
    //   splitModal: false,
    // });
  };

  goBack = () => {
    this.setState({
      showSplittingData: false,
      loadingHasOpenInvoice: false,
      loading: false,
    });
  };

  toggleExeededAmount = () => {
    this.setState({ exceededAmount: !this.state.exceededAmount });
  };

  togglePayingTheBillModal = () => {
    this.setState({ payingTheBillModal: !this.state.payingTheBillModal });
  };

  toggleRefreshModal = () => {
    this.setState({ refreshModal: !this.state.refreshModal });
  };

  toggleViewTipModal = () => {
    this.setState({ viewTip: !this.state.viewTip });
  };

  handleShare = () => {
    this.setState({ underDevelopmentModal: true });
    // const { recordGuid } = this.state;
    // const { orderId } = this.props;
    // if (navigator.share) {
    //   navigator
    //     .share({
    //       title: "invoice",
    //       text: "invoice",
    //       url: `${GET_API_URL}?PaymentGuid=${orderId}`,
    //     })
    //     .then(() => console.log("Shared successfully."))
    //     .catch((error) => console.log("Error sharing:", error));
    // } else {
    //   console.log("Web Share API not supported.");
    // }
  };

  handleMouseEnter = (value) => {
    if (value === "share") {
      this.setState({ isHovered: true });
    }
    if (value === "receipt") {
      this.setState({ isHoveredReceipt: true });
    }
    if (value === "download") {
      this.setState({ isHoveredDownload: true });
    }
    if (value === "email") {
      this.setState({ isHoveredEmail: true });
    }
  };

  handleMouseLeave = (value) => {
    if (value === "share") {
      this.setState({ isHovered: false });
    }
    if (value === "receipt") {
      this.setState({ isHoveredReceipt: false });
    }
    if (value === "download") {
      this.setState({ isHoveredDownload: false });
    }
    if (value === "email") {
      this.setState({ isHoveredEmail: false });
    }
  };

  removePercentage = () => {
    const { totalWithVat, amountLeft } = this.state;
    this.setState({
      totalAmount: totalWithVat,
      other: null,
      amountToPay: parseInt(amountLeft),
      tip: 0,
      showInput: true,
      active: 0,
    });
  };

  render() {
    const {
      other,
      tip,
      totalAmount,
      loading,
      languageModal,
      errors,
      selectedRadio,
      currency,
      splitModal,
      showSplittingData,
      splitOption,
      dataInfo,
      languages,
      splitTheBillItems,
      show,
      menuURL,
      promotionalBtns,
      disablePayFully,
      tenPercentTip,
      fiftenPercentTip,
      twentyPercentTip,
      amountToPay,
      message,
      hasOpenInvoice,
      loadingHasOpenInvoice,
      noExternalIdMsg,
      displayLoader,
      modalTitle,
      splittingModal,
      wrongQR,
      showFullyButton,
      showPartiallyItemsButton,
      showPartiallyAmountButton,
      showPartiallyEvenlyButton,
      showPartiallyButton,
      disabledPayFully,
    } = this.state;
    const { t, backgroundLogo, logoURL } = this.props;
    const renderLanguages = languages?.map((lang, index) => (
      <>
        <MDBRow
          className={`${
            i18n.language === "en"
              ? " left-to-right mt-2"
              : " right-to-left mt-2 "
          }`}
        >
          <MDBCol
            size="2"
            className={`${
              i18n.language === "en" ? "left-to-right" : "right-to-left"
            }`}
          >
            {lang?.code.toLowerCase() === "ar" && (
              <FixedHeightFlag country="sa" fileType="webp" flagHeight="h24" />
            )}
            {lang?.code.toLowerCase() === "en" && (
              <FixedHeightFlag country="us" fileType="webp" flagHeight="h24" />
            )}
            {lang?.code.toLowerCase() === "fr" && (
              <FixedHeightFlag country="fr" fileType="webp" flagHeight="h24" />
            )}
          </MDBCol>
          <MDBCol
            size="6"
            className={`${
              i18n.language === "en"
                ? "lang-name  left-to-right text-left"
                : "lang-name right-to-left text-right"
            }`}
          >
            {t(lang?.name)}
          </MDBCol>
          <MDBCol size="4">
            <MDBInput
              label={
                <span
                  onClick={() => this.selectFilter(lang.name, index)}
                ></span>
              } // Use label to wrap the text
              onClick={() => this.selectFilter(lang.name, index)}
              checked={selectedRadio === index}
              type="radio"
              id={`radio${index}`}
            />
          </MDBCol>
        </MDBRow>
      </>
    ));

    return (
      <>
        {displayLoader === true ? (
          <Loader />
        ) : (
          <>
            {this.state.viewMenu && (
              <>
                {menuURL ? (
                  <div>
                    <iframe
                      src={menuURL}
                      className="menu-frame"
                      title={t("Menu Url")}
                    ></iframe>
                    <div className="w-100 text-left">
                      <img
                        alt={t("Back")}
                        src={Back}
                        className={"back-arrow"}
                        onClick={this.toggleViewMenu}
                      />
                    </div>
                  </div>
                ) : (
                  []
                )}
              </>
            )}
            {!this.state.payBill ? (
              <section id="homepage">
                <section id="home" className="section">
                  {/* <MDBContainer
                    className={
                      window.innerWidth === 1024
                        ? "iphone-container-img"
                        : "container-img"
                    }
                  > */}
                  <img
                    src={backgroundLogo}
                    width="400px"
                    height="250px"
                    className={`${
                      i18n.language === "en"
                        ? "smiley-bg align-items-end"
                        : "smiley-bg align-items-start"
                    }`}
                    alt="background logo"
                  ></img>
                  {!this.state.paymentCompleted &&
                    renderLanguages?.length > 1 && (
                      <img
                        src={globe}
                        className={`${
                          i18n.language === "en"
                            ? "left-image-container"
                            : "right-image-container"
                        }`}
                        alt="globe icon"
                        onClick={this.toggleLanguageModal}
                      />
                    )}
                  <MDBRow center>
                    <MDBCard className="logo-card">
                      <img
                        src={logoURL}
                        width="178px"
                        height="auto"
                        className="taste-img"
                        alt="branch logo"
                      />
                    </MDBCard>
                  </MDBRow>
                  {!this.state.paymentCompleted ? (
                    <>
                      <MDBRow
                        center
                        className={`${
                          i18n.language === "en"
                            ? " left-to-right mt-5"
                            : " right-to-left  mt-5"
                        }`}
                      >
                        <MDBCard className={"menu-card mt-4"}>
                          <MDBRow className="h-100">
                            <MDBCol size="1" middle>
                              <img
                                src={BlueArrow}
                                alt={t("View Menu")}
                                className={`${
                                  i18n.language === "en"
                                    ? " left-to-right "
                                    : " right-to-left rotate"
                                }`}
                              />
                            </MDBCol>
                            <MDBCol
                              size="8"
                              middle
                              onClick={this.toggleViewMenu}
                            >
                              <p
                                className={`${
                                  i18n.language === "en"
                                    ? "blue-text Montserrat-Medium text-left mt-2"
                                    : "blue-text Montserrat-Medium text-right mt-2"
                                }`}
                              >
                                {t("View Menu")}
                              </p>
                            </MDBCol>
                            <MDBCol middle>
                              <img
                                src={ViewMenuImg}
                                width={""}
                                alt=""
                                className={"icons"}
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCard>
                      </MDBRow>
                      <MDBRow
                        center
                        className={`${
                          i18n.language === "en"
                            ? " left-to-right mt-4"
                            : " right-to-left  mt-4"
                        }`}
                      >
                        <MDBCard
                          className={
                            errors || !hasOpenInvoice
                              ? "disabled-menu-card"
                              : "menu-card"
                          }
                        >
                          <MDBRow
                            style={{
                              cursor: hasOpenInvoice
                                ? "pointer"
                                : "not-allowed",
                            }}
                            className="h-100"
                          >
                            <MDBCol size="1" middle>
                              <img
                                src={BlueArrow}
                                alt=""
                                className={`${
                                  i18n.language === "en"
                                    ? " left-to-right "
                                    : " right-to-left rotate"
                                }`}
                              />
                            </MDBCol>
                            <MDBCol
                              size="8"
                              className="flex-col"
                              middle
                              onClick={
                                hasOpenInvoice === true && this.togglePayBill
                              }
                              // onClick={this.togglePayBill}
                            >
                              <p
                                className={`${
                                  i18n.language === "en" && errors
                                    ? "gray-text Montserrat-Medium text-left m-0"
                                    : i18n.language === "en" && !errors
                                    ? "blue-text Montserrat-Medium text-left m-0"
                                    : i18n.language === "ar" && !errors
                                    ? "blue-text Montserrat-Medium text-right m-0"
                                    : "gray-text Montserrat-Medium text-right m-0"
                                }`}
                              >
                                {loadingHasOpenInvoice ? (
                                  <MDBIcon
                                    icon="spinner"
                                    className="p-2 m-1"
                                    spin
                                  />
                                ) : (
                                  t("Pay The Bill")
                                )}
                              </p>
                            </MDBCol>
                            <MDBCol middle>
                              <img
                                src={
                                  errors || !hasOpenInvoice
                                    ? disabledBill
                                    : Bill
                                }
                                className={"icons"}
                                alt="Pay The Bill"
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCard>
                      </MDBRow>
                      <MDBRow center>
                        <MDBCard className="imgs-card mr-3 ml-3">
                          <MDBRow center>
                            {promotionalBtns?.details?.length > 0
                              ? promotionalBtns?.details?.map((item, index) => (
                                  <MDBCol size="4" key={index}>
                                    <MDBRow center>
                                      <a
                                        href={item?.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          alt="Promotional Button"
                                          src={item?.iconIdentifier}
                                          className="logo-img"
                                          height="50"
                                        />
                                      </a>
                                    </MDBRow>
                                    <MDBRow center>
                                      <p className="titles">{t(item?.title)}</p>
                                    </MDBRow>
                                  </MDBCol>
                                ))
                              : []}
                          </MDBRow>
                        </MDBCard>
                      </MDBRow>
                      <MDBRow className={"inner-row"} center>
                        <MDBCol size="12" className="qcheck-logo-col">
                          <img
                            src={Check}
                            className="qcheck-logo"
                            alt="qcheck-logo"
                          />
                        </MDBCol>
                        <MDBCol
                          style={{ marginTop: "-20px" }}
                          size="12"
                          className="copyright"
                        >
                          <span className={"term-text text-left"}>
                            {t("By using Q-Check you accept our")}
                          </span>
                          <span className={"term-word text-right"}>
                            {t("Terms & Conditions")}
                          </span>
                        </MDBCol>
                      </MDBRow>
                    </>
                  ) : (
                    <>[]</>
                  )}
                  {/* </MDBContainer> */}
                </section>
              </section>
            ) : (
              //
              <>
                {!showSplittingData && (
                  <section
                    className={`${
                      i18n.language === "en"
                        ? " left-to-right pay-bill"
                        : " right-to-left  pay-bill"
                    }`}
                  >
                    <div style={{ height: "80vh" }}>
                      <div className="centered-div mt-4">
                        <MDBRow md="12">
                          <MDBCol size="4">
                            <span>
                              <img
                                alt={t("Back")}
                                src={i18n.language === "en" ? BackEN : BackAR}
                                className="d-flex text-left back-btn cursor-pointer"
                                onClick={this.toggleDiscardTheModal}
                              />
                            </span>
                          </MDBCol>
                        </MDBRow>

                        <div className={"top-bar"}>
                          <MDBRow center className="mt-4 mb-0">
                            {/* <MDBCol size="10" className="text-center"> */}
                            <MDBTooltip tag="span" placement="top" domElement>
                              <span
                                className={
                                  i18n.language === "en"
                                    ? "title-card parentCell"
                                    : "arabic-title-card parentCell"
                                }
                              >
                                {t(this.state.dataInfo?.title)}
                              </span>
                              <span> {t(this.state.dataInfo?.title)}</span>
                            </MDBTooltip>

                            <img
                              src={`${
                                i18n.language === "en" ? Share : arabicShare
                              }`}
                              alt="Share Icon"
                              onClick={this.handleShare}
                              className={`${
                                i18n.language === "en"
                                  ? "share-icon"
                                  : "arabic-share-icon"
                              }`}
                            ></img>
                            {/* </MDBCol> */}
                          </MDBRow>
                          <MDBRow center className="mb-3">
                            {/* <MDBCol size="12" className="text-center"> */}
                            <MDBTooltip tag="span" placement="top" domElement>
                              <span className="title-desc parentCell">
                                {t(this.state.dataInfo?.description)}
                              </span>
                              <span>
                                {" "}
                                {t(this.state.dataInfo?.description)}
                              </span>
                            </MDBTooltip>

                            <p className="t"></p>
                            {/* </MDBCol> */}
                          </MDBRow>
                        </div>

                        <div className="body-bar">
                          <div className="pt-3"></div>
                          {this.state.dataInfo?.items.map((x) => {
                            return (
                              <>
                                <MDBContainer>
                                  <MDBRow className="">
                                    <MDBCol
                                      size="2"
                                      className={`${
                                        i18n.language === "en"
                                          ? " left-to-right quantity text-right"
                                          : " right-to-left  quantity text-left"
                                      }`}
                                    >
                                      {x.quantity}
                                    </MDBCol>
                                    <MDBCol
                                      className={`${
                                        i18n.language === "en"
                                          ? " left-to-right text-left"
                                          : " right-to-left  text-right"
                                      }`}
                                      size="7"
                                    >
                                      <p className="food-names mb-0">
                                        {t(x?.name)}
                                      </p>
                                      <p className="ingredientsDesktop mb-0">
                                        {t(x?.description)}
                                      </p>
                                    </MDBCol>
                                    <MDBCol size="3">
                                      <p
                                        className={`${
                                          i18n.language === "en"
                                            ? "food-names"
                                            : " left-to-right  food-names"
                                        }`}
                                      >
                                        {currency === "USD" ? "$" : currency}
                                        {parseFloat(
                                          (x?.quantity || 0) *
                                            (x?.itemPrice || 0)
                                        ).toFixed(2)}
                                      </p>
                                    </MDBCol>
                                  </MDBRow>
                                </MDBContainer>
                              </>
                            );
                          })}
                        </div>
                        <div
                          className={`${
                            i18n.language === "en"
                              ? " left-to-right bottom-bar"
                              : " right-to-left  bottom-bar"
                          }`}
                        >
                          <MDBContainer>
                            <div className="pt-3"></div>
                            <MDBRow className="mt-3 mb-3">
                              <MDBCol size="6">
                                <p
                                  className="food-names"
                                  style={{
                                    display: "flex",
                                    paddingLeft:
                                      i18n.language === "en" ? "30px" : "0",
                                    paddingRight:
                                      i18n.language === "ar" ? "20px" : "0",
                                  }}
                                >
                                  {t("VAT")}
                                </p>
                              </MDBCol>
                              <MDBCol
                                size="6"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingRight:
                                    i18n.language === "en" ? "36px" : "0",
                                  paddingLeft:
                                    i18n.language !== "en" ? "36px" : "0",
                                }}
                              >
                                <p
                                  className={`${
                                    i18n.language === "en"
                                      ? "food-names"
                                      : "food-names left-to-right"
                                  }`}
                                >
                                  {currency === "USD" ? "$" : currency}{" "}
                                  {this.state.vat}
                                </p>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow
                              style={{ marginTop: "-15px" }}
                              className="mb-3"
                            >
                              <MDBCol size="6">
                                <p
                                  style={{
                                    marginLeft:
                                      i18n.language === "en" ? "-9px" : "0",
                                    paddingRight:
                                      i18n.language === "en" ? "0px" : "20px",
                                    textAlign:
                                      i18n.language === "en" ? "" : "start",
                                  }}
                                  className="total"
                                >
                                  {t("Total Bill")}
                                </p>
                              </MDBCol>
                              <MDBCol
                                size="6"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingRight:
                                    i18n.language === "en" ? "36px" : "0",
                                  paddingLeft:
                                    i18n.language === "ar" ? "36px" : "0",
                                }}
                              >
                                <p
                                  className={`${
                                    i18n.language === "en"
                                      ? "total"
                                      : "total left-to-right"
                                  }`}
                                >
                                  {currency === "USD" ? "$" : currency}{" "}
                                  {dataInfo.totalAmountWithVat}
                                </p>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                      </div>

                      <MDBRow
                        center
                        className={`${
                          i18n.language === "en"
                            ? " left-to-right mt-3 test"
                            : " right-to-left mt-3 test"
                        }`}
                      >
                        <p className="swipe">{t("Swipe Down To Refresh")}</p>
                      </MDBRow>

                      <MDBModal
                        isOpen={this.state.viewTip}
                        toggle={this.toggleViewTipModal}
                        size="sm"
                        className="modals"
                        centered
                      >
                        <MDBModalBody>
                          {/* <MDBRow center className="mt-2" size="12"> */}
                          <MDBRow
                            size="12"
                            center
                            className={`${
                              i18n.language === "en"
                                ? ` left-to-right `
                                : ` right-to-left`
                            }`}
                          >
                            <p className="question">
                              {t("Would you like to add a tip?")}
                            </p>
                          </MDBRow>
                          {currency === "USD" ? (
                            <MDBRow size="12" center className="d-flex">
                              <MDBCol size="4" className="p-1">
                                <MDBCard
                                  onClick={() => this.handleTipChange(10)}
                                  className={
                                    this.state.active === 1
                                      ? `mini-cards-active ${
                                          i18n.language === "en"
                                            ? "  "
                                            : " left-to-right"
                                        }`
                                      : `mini-cards ${
                                          i18n.language === "en"
                                            ? "  "
                                            : " left-to-right"
                                        }`
                                  }
                                >
                                  <MDBRow className="d-flex justify-content-center">
                                    <i className="far fa-thumbs-up"></i>
                                    <div
                                      style={{
                                        display: "grid",
                                        margin: "-4px 0px 0px 0px",
                                      }}
                                    >
                                      <span className="text fs-6">10%</span>
                                      <span className="fs-11">
                                        {tenPercentTip} {currency}{" "}
                                      </span>
                                    </div>
                                  </MDBRow>
                                </MDBCard>
                              </MDBCol>
                              <MDBCol size="4" className="p-1">
                                <MDBCard
                                  onClick={() => this.handleTipChange(15)}
                                  className={
                                    this.state.active === 2
                                      ? `mini-cards-active ${
                                          i18n.language === "en"
                                            ? " ml-1 "
                                            : " mr-1 left-to-right"
                                        }`
                                      : `mini-cards ${
                                          i18n.language === "en"
                                            ? "ml-1  "
                                            : "mr-1  left-to-right"
                                        }`
                                  }
                                >
                                  <MDBRow className="d-flex justify-content-center">
                                    <i className="far fa-smile"></i>
                                    <div
                                      style={{
                                        display: "grid",
                                        margin: "-4px 0px 0px 0px",
                                      }}
                                    >
                                      <span className="text fs-6">15%</span>
                                      <span className="fs-11">
                                        {fiftenPercentTip} {currency}{" "}
                                      </span>
                                    </div>
                                  </MDBRow>
                                </MDBCard>
                              </MDBCol>
                              <MDBCol size="4" className="p-1">
                                <MDBCard
                                  onClick={() => this.handleTipChange(20)}
                                  className={
                                    this.state.active === 3
                                      ? `mini-cards-active ${
                                          i18n.language === "en"
                                            ? " ml-1 "
                                            : " mr-1 left-to-right"
                                        }`
                                      : ` mini-cards ${
                                          i18n.language === "en"
                                            ? "ml-1 "
                                            : "mr-1 left-to-right"
                                        }`
                                  }
                                >
                                  <MDBRow className="d-flex justify-content-center">
                                    <i className="far fa-grin"></i>
                                    <div
                                      style={{
                                        display: "grid",
                                        margin: "-4px 0px 0px 0px",
                                      }}
                                    >
                                      <span className="text fs-6">20%</span>
                                      <span className="fs-11">
                                        {twentyPercentTip} {currency}{" "}
                                      </span>
                                    </div>
                                  </MDBRow>
                                </MDBCard>
                              </MDBCol>
                            </MDBRow>
                          ) : (
                            <MDBRow center>
                              <MDBCard
                                onClick={() => this.handleTipChange(10)}
                                className={
                                  this.state.active === 1
                                    ? `mini-cards-active ${
                                        i18n.language === "en"
                                          ? "  "
                                          : " left-to-right"
                                      }`
                                    : `mini-cards ${
                                        i18n.language === "en"
                                          ? "  "
                                          : "left-to-right"
                                      }`
                                }
                              >
                                <MDBRow
                                  style={{
                                    display:
                                      this.state.active === 1 ? "grid" : "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginTop:
                                        this.state.active === 1 ? "-5px" : "",
                                    }}
                                  >
                                    <i className="far fa-thumbs-up"></i>
                                    {this.state.active === 1 && (
                                      <span className="text fs-6">10%</span>
                                    )}
                                  </div>
                                  {this.state.active === 1 ? (
                                    <span className="fs-11">
                                      {tip}
                                      {currency}{" "}
                                    </span>
                                  ) : (
                                    <span className="total ml-1">10%</span>
                                  )}
                                </MDBRow>
                              </MDBCard>
                              <MDBCard
                                onClick={() => this.handleTipChange(15)}
                                className={
                                  this.state.active === 2
                                    ? `mini-cards-active ${
                                        i18n.language === "en"
                                          ? " ml-1 "
                                          : " mr-1 left-to-right"
                                      }`
                                    : `mini-cards ${
                                        i18n.language === "en"
                                          ? "ml-1 "
                                          : "mr-1 left-to-right"
                                      }`
                                }
                              >
                                <MDBRow
                                  style={{
                                    display:
                                      this.state.active === 2 ? "grid" : "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginTop:
                                        this.state.active === 2 ? "-5px" : "",
                                    }}
                                  >
                                    <i className="far fa-smile"></i>

                                    {this.state.active === 2 && (
                                      <span className="text fs-6">15%</span>
                                    )}
                                  </div>
                                  {this.state.active === 2 ? (
                                    <span className="fs-11">
                                      {tip}
                                      {currency}{" "}
                                    </span>
                                  ) : (
                                    <span className="total ml-1">15%</span>
                                  )}
                                </MDBRow>
                              </MDBCard>
                              <MDBCard
                                onClick={() => this.handleTipChange(20)}
                                className={
                                  this.state.active === 3
                                    ? `mini-cards-active ${
                                        i18n.language === "en"
                                          ? " ml-1 "
                                          : "mr-1 left-to-right"
                                      }`
                                    : `mini-cards ${
                                        i18n.language === "en"
                                          ? "ml-1  "
                                          : "mr-1  left-to-right"
                                      }`
                                }
                              >
                                <MDBRow
                                  style={{
                                    display:
                                      this.state.active === 3 ? "grid" : "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <i classname="far fa-grin"></i>
                                    {this.state.active === 3 && (
                                      <span className="text fs-6">20%</span>
                                    )}
                                  </div>
                                  {this.state.active === 3 ? (
                                    <span className="fs-11">
                                      {tip}
                                      {currency}{" "}
                                    </span>
                                  ) : (
                                    <span className="total ml-1">20%</span>
                                  )}
                                </MDBRow>
                              </MDBCard>
                            </MDBRow>
                          )}

                          {!this.state.showInput ? (
                            <MDBRow className="mt-4 pb-4" center>
                              <MDBCard
                                onClick={() => this.removePercentage()}
                                className={` card-other ${
                                  i18n.language === "en"
                                    ? "ml-1"
                                    : "mr-1 left-to-right"
                                }`}
                              >
                                <MDBRow className="d-flex justify-content-center">
                                  <span
                                    className="total ml-1"
                                    onClick={() => {
                                      this.toggleShowInput();
                                      this.setState({ tip: null }, () =>
                                        this.getTotalWithoutTip()
                                      );
                                    }}
                                  >
                                    {t("Other")}
                                  </span>
                                </MDBRow>
                              </MDBCard>
                            </MDBRow>
                          ) : (
                            <MDBRow className="mt-4 pb-4" center>
                              <MDBCard
                                className={
                                  this.state.showInput
                                    ? `card-other-active overflow-hidden ${
                                        i18n.language === "en"
                                          ? " ml-1 "
                                          : " mr-1 left-to-right"
                                      }`
                                    : `card-other overflow-hidden ${
                                        i18n.language === "en"
                                          ? "ml-1  "
                                          : "mr-1 left-to-right"
                                      }`
                                }
                                onClick={() => this.removePercentage()}
                              >
                                <MDBRow className="d-flex justify-content-center">
                                  <MDBInput
                                    className="input-card"
                                    value={other}
                                    ref={this.inputRef}
                                    pattern="[0-9]*"
                                    type="number"
                                    id="inputRef"
                                    onChange={(e) => {
                                      const inputValue = e.target.value;

                                      // Determine the maximum length based on currency
                                      const maxLength =
                                        currency === "USD"
                                          ? 3
                                          : currency === "LBP"
                                          ? 8
                                          : 3;

                                      // Keep only the first 'maxLength' digits
                                      const validInput = inputValue
                                        .replace(/\D/g, "")
                                        .slice(0, maxLength);

                                      this.setState(
                                        {
                                          other: validInput,
                                          tip: validInput, // Update tip with the same value
                                        },
                                        () => this.updateAmount()
                                      );
                                    }}
                                  ></MDBInput>
                                  <span className="fs-13">
                                    {currency === "USD" ? "USD" : currency}
                                  </span>
                                </MDBRow>
                              </MDBCard>
                            </MDBRow>
                          )}
                          {/* </MDBRow> */}
                        </MDBModalBody>
                      </MDBModal>

                      <MDBRow center>
                        <img src={Check} alt={t("Check")} />
                      </MDBRow>
                      <MDBRow center style={{ marginTop: "-20px" }}>
                        <span className={"term-text"}>
                          {t("By using Q-Check you accept our")}
                        </span>
                        <span className={"term-word"}>
                          {t("Terms & Conditions")}
                        </span>
                      </MDBRow>
                    </div>
                    <div style={{ height: "20vh" }}>
                      <MDBCard
                        className={"pay-btn-card"}
                        style={this.state.viewTip ? { zIndex: "99999" } : null}
                      >
                        <div className={"centered-div mt-4"}>
                          {show ? (
                            <MDBContainer>
                              {this.state.amountLeft !==
                                this.state.totalWithVat && (
                                <MDBRow>
                                  <MDBCol
                                    size="6"
                                    className="align-items-start d-flex"
                                    middle
                                  >
                                    <span className={"tipAmount"}>
                                      {t("Amount Left To Pay")}
                                    </span>
                                  </MDBCol>
                                  <MDBCol size="6" middle>
                                    <span
                                      className={`${
                                        i18n.language === "en"
                                          ? "float-right tipValue "
                                          : " left-to-right  float-left tipValue"
                                      }`}
                                    >
                                      {currency === "USD" ? "$" : currency}{" "}
                                      {/* {this.state.amountLeft} */}
                                      {dataInfo?.totalAmountWithVat}
                                    </span>
                                  </MDBCol>
                                  <hr className="line"></hr>
                                </MDBRow>
                              )}

                              {this.state.viewTip && (
                                <MDBRow>
                                  <MDBCol
                                    size="6"
                                    className="align-items-start d-flex"
                                    middle
                                  >
                                    <span className={"tipAmount"}>
                                      {t("Your Tip Amount")}
                                    </span>
                                  </MDBCol>
                                  <MDBCol size="6" middle>
                                    <span
                                      className={`${
                                        i18n.language === "en"
                                          ? "float-right tipValue"
                                          : " left-to-right float-left tipValue"
                                      }`}
                                    >
                                      {currency === "USD" ? "$" : currency}{" "}
                                      {tip}
                                    </span>
                                  </MDBCol>
                                </MDBRow>
                              )}
                              <MDBRow>
                                <MDBCol
                                  size="6"
                                  className="align-items-start d-flex"
                                  middle
                                >
                                  <span className={"totalBill"}>
                                    {t("Total Bill")}
                                  </span>
                                </MDBCol>
                                <MDBCol size="6" middle>
                                  <span
                                    className={`${
                                      i18n.language === "en"
                                        ? "float-right blue-value "
                                        : "left-to-right  float-left blue-value"
                                    }`}
                                  >
                                    {currency === "USD" ? "$" : currency}{" "}
                                    {/* {this.roundNumber(amountToPay)} */}
                                    {dataInfo.totalAmountWithVat}
                                  </span>
                                </MDBCol>
                              </MDBRow>

                              <hr className="dotted-line"></hr>
                              {showPartiallyButton === true &&
                                showFullyButton !== true && (
                                  <>
                                    {disablePayFully === true ? (
                                      <MDBRow>
                                        {!this.state.viewTip && (
                                          <MDBBtn
                                            className={"split-btn w-100"}
                                            onClick={() =>
                                              this.toggleSplitModal()
                                            }
                                          >
                                            {t("Split Bill")}
                                          </MDBBtn>
                                        )}
                                      </MDBRow>
                                    ) : (
                                      <MDBRow>
                                        <MDBCol size="6">
                                          {!this.state.viewTip && (
                                            <MDBBtn
                                              className={"split-btn w-100"}
                                              onClick={() =>
                                                this.toggleSplitModal()
                                              }
                                            >
                                              {t("Split Bill")}
                                            </MDBBtn>
                                          )}
                                        </MDBCol>
                                        <MDBCol size="6">
                                          {this.state.viewTip && (
                                            //openViewTip
                                            <MDBBtn
                                              className="pay-now-btn"
                                              onClick={() =>
                                                this.checkTransactionLock(
                                                  this.state.token
                                                )
                                              }
                                            >
                                              {loading ? (
                                                <MDBIcon
                                                  icon="spinner"
                                                  className="fs-13"
                                                  spin
                                                />
                                              ) : (
                                                t("Pay Fully")
                                              )}
                                            </MDBBtn>
                                          )}
                                          {!this.state.viewTip && (
                                            <MDBBtn
                                              className="pay-now-btn"
                                              onClick={() => this.openViewTip()}
                                            >
                                              {loading ? (
                                                <MDBIcon
                                                  icon="spinner"
                                                  className="fs-13"
                                                  spin
                                                />
                                              ) : (
                                                t("Pay Fully")
                                              )}
                                            </MDBBtn>
                                          )}
                                        </MDBCol>
                                      </MDBRow>
                                    )}
                                  </>
                                )}
                              {showFullyButton === true &&
                                showPartiallyButton !== true && (
                                  <MDBRow>
                                    {/* {this.state.viewTip && ( */}
                                    <MDBBtn
                                      className="pay-now-btn w-100"
                                      onClick={() =>
                                        this.state.viewTip
                                          ? this.payNow()
                                          : this.openViewTip()
                                      }
                                      disabled={disabledPayFully}
                                    >
                                      {loading ? (
                                        <MDBIcon
                                          icon="spinner"
                                          className="fs-13"
                                          spin
                                        />
                                      ) : (
                                        t("Pay Fully")
                                      )}
                                    </MDBBtn>
                                    {/* )} */}
                                    {/* {!this.state.viewTip && (
                                    <MDBBtn
                                      className="pay-now-btn w-100"
                                      onClick={() => this.openViewTip()}
                                    >
                                      {loading ? (
                                        <MDBIcon
                                          icon="spinner"
                                          className="fs-13"
                                          spin
                                        />
                                      ) : (
                                        t("Pay Fully")
                                      )}
                                    </MDBBtn>
                                  )} */}
                                  </MDBRow>
                                )}

                              {showPartiallyButton === true &&
                                showFullyButton === true && (
                                  <>
                                    {disablePayFully === true ? (
                                      <MDBRow>
                                        {!this.state.viewTip && (
                                          <MDBBtn
                                            className={"split-btn w-100"}
                                            onClick={() =>
                                              this.toggleSplitModal()
                                            }
                                          >
                                            {t("Split Bill")}
                                          </MDBBtn>
                                        )}
                                      </MDBRow>
                                    ) : (
                                      <MDBRow>
                                        <MDBCol size="6">
                                          {!this.state.viewTip && (
                                            <MDBBtn
                                              className={"split-btn w-100"}
                                              onClick={() =>
                                                this.toggleSplitModal()
                                              }
                                            >
                                              {t("Split Bill")}
                                            </MDBBtn>
                                          )}
                                        </MDBCol>
                                        <MDBCol size="6">
                                          {this.state.viewTip && (
                                            //openViewTip
                                            <MDBBtn
                                              className="pay-now-btn"
                                              onClick={() =>
                                                this.checkTransactionLock(
                                                  this.state.token
                                                )
                                              }
                                            >
                                              {loading ? (
                                                <MDBIcon
                                                  icon="spinner"
                                                  className="fs-13"
                                                  spin
                                                />
                                              ) : (
                                                t("Pay Fully")
                                              )}
                                            </MDBBtn>
                                          )}
                                          {!this.state.viewTip && (
                                            <MDBBtn
                                              className="pay-now-btn"
                                              onClick={() => this.openViewTip()}
                                            >
                                              {loading ? (
                                                <MDBIcon
                                                  icon="spinner"
                                                  className="fs-13"
                                                  spin
                                                />
                                              ) : (
                                                t("Pay Fully")
                                              )}
                                            </MDBBtn>
                                          )}
                                        </MDBCol>
                                      </MDBRow>
                                    )}
                                  </>
                                )}
                            </MDBContainer>
                          ) : (
                            <MDBContainer>
                              {this.state.viewTip && (
                                <MDBRow
                                  className="d-flex align-items-center justify-content-center"
                                  center
                                >
                                  <MDBCol
                                    size="6"
                                    className="align-items-start d-flex"
                                    middle
                                  >
                                    <span className={"tipAmount"}>
                                      {t("Your Tip Amount")}
                                    </span>
                                  </MDBCol>
                                  <MDBCol size="6" middle>
                                    <p
                                      className={`${
                                        i18n.language === "en"
                                          ? ` left-to-right text-right m-0`
                                          : ` right-to-left text-left m-0`
                                      }`}
                                    >
                                      {currency === "USD" ? "$" : currency}{" "}
                                      {tip}
                                    </p>
                                  </MDBCol>
                                  <hr className="dotted-line"></hr>
                                </MDBRow>
                              )}
                              <MDBRow
                                className="d-flex align-items-center justify-content-center"
                                center
                              >
                                <MDBCol
                                  size="6"
                                  className="align-items-start d-flex"
                                  middle
                                >
                                  <span className="totalBill">
                                    {t("Total Bill")}
                                  </span>
                                </MDBCol>
                                <MDBCol size="6" middle>
                                  <p
                                    className={`${
                                      i18n.language === "en"
                                        ? ` left-to-right text-right m-0 blue-value`
                                        : ` right-to-left text-left m-0`
                                    }`}
                                  >
                                    {currency === "USD" ? "$" : currency}
                                    {totalAmount}
                                  </p>
                                </MDBCol>
                                <hr className="dotted-line"></hr>
                              </MDBRow>
                              {/* {showPartiallyButton && (
                                <MDBRow>
                                  <MDBCol size="6">
                                    <MDBBtn
                                      className="pay-now-btn w-100"
                                      onClick={() => this.payNow()}
                                    >
                                      {loading ? (
                                        <MDBIcon
                                          icon="spinner"
                                          className="fs-13"
                                          spin
                                        />
                                      ) : (
                                        t("Pay Fully")
                                      )}
                                    </MDBBtn>
                                  </MDBCol>
                                </MDBRow>
                              )} */}
                              {showFullyButton === true && (
                                <MDBRow>
                                  <MDBBtn
                                    className="pay-now-btn w-100"
                                    onClick={() => this.payNow()}
                                  >
                                    {loading ? (
                                      <MDBIcon
                                        icon="spinner"
                                        className="fs-13"
                                        spin
                                      />
                                    ) : (
                                      t("Pay Fully")
                                    )}
                                  </MDBBtn>
                                </MDBRow>
                              )}
                            </MDBContainer>
                          )}
                        </div>
                      </MDBCard>
                    </div>
                  </section>
                )}
                {showSplittingData && this.state.token?.trim() && (
                  <SplitTheBill
                    visitorId={this.state.visitorId}
                    token={this.state.token}
                    recordGuid={this.state.recordGuid}
                    splitOption={splitOption}
                    dataInfo={dataInfo}
                    goBack={this.goBack}
                    splitTheBillItems={splitTheBillItems}
                    amountToPay={this.state.totalBill}
                    toggleDiscardTheModal={this.toggleDiscardTheModal}
                    languageCode={this.state.languageCode}
                    showPartiallyAmountButton={showPartiallyAmountButton}
                    showPartiallyEvenlyButton={showPartiallyEvenlyButton}
                    showPartiallyItemsButton={showPartiallyItemsButton}
                  />
                )}
              </>
            )}
          </>
        )}

        <MDBModal
          isOpen={this.state.successModal}
          toggle={this.toggleSuccessModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <MDBCol md="12">
                <p className="modal-title">{t("Hurray!")}</p>
              </MDBCol>
              <MDBCol>
                <p className="modal-text">{t("You’re All Done")}</p>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-4" center>
              <img src={HappyFace} alt={t("Happy Face")} />
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <ModalComponent
          isOpen={splittingModal}
          toggle={this.toggleSplittingTheModal}
          modalTitle={modalTitle}
          message={message}
          img={Splitting}
          imgAlt="Splitting"
          imgClassName={"spliting"}
          onTryAgainClick={this.closeSplittingTheModal}
          btnName="Try Again"
        />

        <ModalComponent
          isOpen={wrongQR}
          toggle={this.toggleWrongQRModal}
          modalTitle={modalTitle}
          message={message}
          img={invalidQR}
          imgAlt="Invalid QR"
          imgClassName={""}
          onTryAgainClick={this.closeWrongQRModal}
          btnName="Try Again"
        />

        <MDBModal
          isOpen={this.state.discardModal}
          toggle={this.toggleDiscardTheModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <p className="modal-text mb-0">{t("Are you sure you want")} </p>
              <br />
              <p className="modal-text mb-0">
                {t("To discard this payment?")}{" "}
              </p>
            </MDBRow>
            <MDBRow center>
              <img alt={t("Discard")} className="discard" src={Discard} />
            </MDBRow>
            <MDBRow center className="mt-1 mb-1">
              <MDBBtn
                className="discard-btn"
                onClick={() => this.closeDiscardModal()}
              >
                {t("Yes Discard")}
              </MDBBtn>
            </MDBRow>
            <MDBRow center className="mt-1 mb-1">
              <MDBBtn
                className="try-again-btn"
                onClick={this.toggleDiscardTheModal}
              >
                {t("Resume Payment")}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.unavailableSystem}
          toggle={this.toggleunavailableSystemModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <p className="modal-title">{t("Something Went Wrong!")}</p>
              <p className="modal-text">
                {t("Sorry The System is temporary unavailable at this time.")}
              </p>
              <p className="modal-text mt-2">{t("Please try again later.")}</p>
            </MDBRow>
            <MDBRow center>
              <img src={Sad} alt={t("Sad")} />
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn
                className="try-again-btn"
                onClick={this.toggleunavailableSystemModal}
              >
                {t("Try Again")}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.wrongBranchModal}
          toggle={this.toggleWrongBranchModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <p className="modal-title">{t("Sorry!")}</p>
            </MDBRow>
            <MDBRow center className="mt-2">
              <p className="modal-text">{noExternalIdMsg}</p>
            </MDBRow>
            <MDBRow center>
              <img src={invalidQR} alt={t("Invalid QR")} />
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn
                className="try-again-btn"
                onClick={this.toggleWrongBranchModal}
              >
                {t("Try Again")}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.exceededAmount}
          toggle={this.toggleExeededAmount}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <p className="modal-text">
                {t("The amount you are paying has exceeded the bill!!")}
              </p>
            </MDBRow>
            <MDBRow center>
              <img src={moneyPaper} alt="Money paper" className="money-paper" />
            </MDBRow>
            <MDBRow center className="mt-3 d-flex">
              <p className="exceeded-amount">{t("Exceeded Amount")}</p>
            </MDBRow>
            <MDBRow center>
              <p className="exceeded-value">
                {currency === "USD" ? "$" : currency}
              </p>
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn
                className="white-with-blue-border-btn"
                onClick={this.toggleWrongQRModal}
              >
                {t("Pay The exact amount")}
              </MDBBtn>
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn className="blue-btn" onClick={this.toggleWrongQRModal}>
                {t("Tip The Rest")}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.payingTheBillModal}
          toggle={this.togglePayingTheBillModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <p className="modal-text">{t(message)}</p>
            </MDBRow>
            <MDBRow center>
              <img src={moneyPaper} alt="Money paper" className="money-paper" />
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn className="blue-btn" onClick={() => this.payNow()}>
                {t("Done")}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={this.state.refreshModal}
          toggle={this.toggleRefreshModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <p className="modal-text">
                {t("Your bill is updated, Kindly")}
                <br></br>
                {t("review your order before")}
                <br></br> {t("proceeding to pay")}
              </p>
            </MDBRow>
            <MDBRow center>
              <img src={invoiceBill} alt="invoice paper" />
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn
                className="blue-btn"
                onClick={() => this.updateAmountLeftToPay()}
              >
                {t("Proceed")}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={languageModal}
          toggle={this.toggleLanguageModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalHeader
            className={`${
              i18n.language === "en"
                ? " left-to-right languageHeadder"
                : " right-to-left  languageHeadder"
            }`}
          >
            {t("Select Language")}
          </MDBModalHeader>
          <MDBModalBody>
            {renderLanguages}
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn
                className="white-with-blue-border-btn"
                onClick={this.toggleLanguageModal}
              >
                {t("Cancel")}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
        <MDBModal
          isOpen={splitModal}
          toggle={this.toggleSplitModal}
          size="sm"
          className={`${
            i18n.language === "en"
              ? " left-to-right modals"
              : " right-to-left  modals"
          }`}
          centered
          id="split-bill-modal"
        >
          <MDBModalBody style={{ height: "350px" }}>
            <MDBContainer>
              <MDBRow
                className="mt-2"
                center
                style={{
                  textAlign: i18n.language === "en" ? "start" : "justify",
                }}
              >
                <MDBCol size="12">
                  <p className="modal-title">{t("Split The Bill")}</p>
                </MDBCol>
                <MDBCol size="12">
                  <p className="modal-text ">
                    {t("Select the splitting method")}{" "}
                    {t("that fits you best!")}
                  </p>
                  {/* <p className='modal-text'> </p> */}
                </MDBCol>
              </MDBRow>
              {showPartiallyItemsButton && (
                <MDBRow
                  className="mb-3"
                  center
                  onClick={() => this.openSplitFeature(1)}
                >
                  <MDBCard className="split-options-card">
                    <MDBRow md="12" className="h-100 d-flex align-items-center">
                      <MDBCol
                        size="3"
                        className={`${
                          i18n.language === "en" ? "text-right" : " text-left"
                        }`}
                      >
                        <img src={splitByItems} alt="Split on items"></img>
                      </MDBCol>

                      <MDBCol
                        size="9"
                        className={`${
                          i18n.language === "en"
                            ? " left-to-right text-left pl-0"
                            : " right-to-left  text-right pr-0"
                        }`}
                      >
                        <span className="split-option">
                          {t("Pay For Your Items")}
                        </span>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </MDBRow>
              )}
              {showPartiallyEvenlyButton && (
                <MDBRow
                  className="mb-3  "
                  center
                  onClick={() => this.openSplitFeature(2)}
                >
                  <MDBCard className="split-options-card">
                    <MDBRow md="12" className="h-100 d-flex align-items-center">
                      <MDBCol
                        size="3"
                        className={`${
                          i18n.language === "en" ? "text-right" : " text-left"
                        }`}
                      >
                        <img src={splitEvenly} alt="Split on items"></img>
                      </MDBCol>
                      <MDBCol
                        size="9"
                        className={`${
                          i18n.language === "en"
                            ? " left-to-right text-left pl-0"
                            : " right-to-left  text-right pr-0"
                        }`}
                      >
                        <span className="split-option">
                          {t("Divide The Bill Evenly")}
                        </span>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </MDBRow>
              )}
              {showPartiallyAmountButton && (
                <MDBRow
                  center
                  className="mb-3"
                  onClick={() => this.openSplitFeature(3)}
                >
                  <MDBCard className="split-options-card">
                    <MDBRow md="12" className="h-100 d-flex align-items-center">
                      <MDBCol
                        size="3"
                        className={`${
                          i18n.language === "en" ? "text-right" : " text-left"
                        }`}
                      >
                        <img src={splitCustom} alt="Split on items"></img>
                      </MDBCol>
                      <MDBCol
                        size="9"
                        className={`${
                          i18n.language === "en"
                            ? " left-to-right text-left pl-0"
                            : " right-to-left  text-right pr-0"
                        }`}
                      >
                        <span className="split-option">
                          {t("Pay A Custom Amount")}
                        </span>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.underDevelopmentModal}
          toggle={this.toggleUnderDevelopment}
          size="md"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow className="mt-2">
              <MDBCol>
                <p className="modal-text mb-0 text-initial">
                  {t("This feature will be available soon.")}
                </p>
                <p className="modal-text mb-0 text-initial">
                  {t(" Thank you for your patience!")}
                </p>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication ? state.authentication.token : "",
  coreMerchantValue: state?.mediaDirectory
    ? state?.mediaDirectory?.coreMerchantValue
    : "",
  accessToken: state?.auth ? state?.auth?.accessToken : "",
  refreshToken: state?.auth ? state?.auth?.refreshToken : "",
  backgroundLogo: state?.mediaDirectory
    ? state?.mediaDirectory?.backgroundLogo
    : "",
  logoURL: state?.mediaDirectory ? state?.mediaDirectory?.logoURL : "",
  orderId: state?.mediaDirectory ? state?.mediaDirectory?.orderId : "",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTokens: (aceessToken, refreshToken) =>
    dispatch(setTokens(aceessToken, refreshToken)),
  setCoreMerchantValue: (coreMerchantValue) =>
    dispatch(setCoreMerchantValue(coreMerchantValue)),
  setImageUrls: (backgroundLogo, logoURL) =>
    dispatch(setImageUrls(backgroundLogo, logoURL)),
  setOrderId: (orderId) => dispatch(setOrderId(orderId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation("translations")(HomePage))
);
