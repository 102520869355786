export const MERCH_APP = "https://merchapp-dev.montylocal.net/api/v1/admin/";
export const CONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Tenant-Key": "f10d389b-9ad7-4d35-bac4-9edfa45259ef",
    "Login-Signature":
      "JBBhDGpeDm3vKtak/xrnNpgv7QQsYmLi172iDl6MLOoR+rNm4wLYOddxjgXrSHQq/43jXVtds7HfJbr00rkGQg==",
    "Content-Type": "application/json",
    "Accept-Language": localStorage.getItem("app_lang") === "ar" ? "AR" : "EN",
  },
};

export const API_IDENTITY_2 = `${process.env.REACT_APP_API_REFRESH_TOKEN}`;
export const API_IDENTITY_TRANSACTION = `${process.env.REACT_APP_API_TRANSACTION_URL}/api/v1`;
export const API_IDENTITY_MEMBER = `${process.env.REACT_APP_API_MEMBER_URL}/api/v1`;

export const API_URL = `${process.env.REACT_APP_API_URL}`;
export const API_MEDIA_URL = `${process.env.REACT_APP_API_MEDIA_URL}`;

export const REFRESH_TOKEN = API_IDENTITY_2 + "Auth/Refresh";
export const API_IDENTITY = process.env.REACT_APP_API_URL_IDENTITY;
export const GET_TERMINAL = API_IDENTITY + "/v1/terminal";
export const LOGIN_GUEST = API_IDENTITY_MEMBER + "/auth/guest-login";
export const HAS_OPEN_INVOICE = API_IDENTITY + "/v1/terminal/has-open-invoice";
export const TRANSACTION_LOCKED =
  API_IDENTITY_TRANSACTION + "/pay/check-if-transaction-locked";

//scan
export const SCAN_INFO = API_IDENTITY + "/v1/terminal/invoice-pos";
export const GET_API_URL = `${API_IDENTITY}/v1/transaction/get-transaction-summary`;

//pay
export const PAY_NOW = MERCH_APP + "q-check/invoices";

//update Tip
export const UPDATE_TIP = API_IDENTITY + "/v1/pay/fully";
export const PARTIALLY_PAY = API_IDENTITY + "/v1/pay/partially";
export const DONWLOAD_INVOICE =
  API_IDENTITY + "/v1/transaction/get-transaction-summary";

//get Media path

export const GET_FILE_DIRCTORY =
  API_MEDIA_URL + "/api/v1/Config/get-config-media-paths";

export const GET_MEDIA_LINK = API_MEDIA_URL + "/api/v1/File/Get/";
export const LOGINCONFIG = {
  headers: {
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // "Access-Control-Allow-Headers":
    //   "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Content-Type": "application/json",
  },
};
