import React, { Component } from "react";
import Lottie from "lottie-react";
import animationData from "./Loader.json"; // Replace with your animation file

class LoadingComponent extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          style={{ width: "70vh", height: "100vh" }}
        />
      </div>
    );
  }
}

export default LoadingComponent;
