// ModalComponent.js
import React from "react";
import { MDBModal, MDBModalBody, MDBRow, MDBBtn } from "mdbreact";
import { useTranslation } from "react-i18next";

const ModalComponent = ({
  isOpen,
  toggle,
  modalTitle,
  message,
  img,
  imgAlt,
  imgClassName,
  onTryAgainClick,
  btnName,
}) => {
  const { t } = useTranslation();

  return (
    <MDBModal
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      className="modals"
      centered
    >
      <MDBModalBody>
        <MDBRow center className="mt-2">
          <p className="modal-title">{t(modalTitle)}</p>
        </MDBRow>
        <MDBRow center className="mt-2">
          <p className="modal-text mb-0">{t(message)}</p>
        </MDBRow>
        <MDBRow center>
          <img alt={t(imgAlt)} className={imgClassName} src={img} />
        </MDBRow>
        <MDBRow center className="mt-3 mb-3">
          <MDBBtn className="try-again-btn" onClick={onTryAgainClick}>
            {t(btnName)}
          </MDBBtn>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

export default ModalComponent;
