import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en.json";
import ar from "./locale/ar.json";

// for references about the 7 lines of code below check: https://levelup.gitconnected.com/learn-how-to-translate-react-with-i18next-a8ec4a8b737d
const allowedLanguages = ["en", "ar"];
let lng = "en";
// In here we are checkin if in the localStorage there was a key mvp6_language that had the language stored, if not then simply we will stick with the defaul 
const storageLanguage = localStorage.getItem("app_lang");
if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1)
    lng = storageLanguage;
// for references about the upper 7 lines of code check: https://levelup.gitconnected.com/learn-how-to-translate-react-with-i18next-a8ec4a8b737d

i18n
    .use(initReactI18next)
    .init({
        resources: { en, ar },
        lng,
        fallbackLng: "en", // go to english version if the language in _lng fails to load 
        debug: process.env.NODE_ENV !== "production",
        ns: ["translations"], // this namespace should match what is inside the locale files
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            wait: true
        }
    });

export default i18n;