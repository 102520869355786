import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';

import { routesWithoutAuthValidation as noValidationRoutes } from "./Routes";

import HomePage from "../Containers/Home/HomePage"
import i18n from "../i18n-config/i18n";

class Routes extends Component {

    componentDidMount(){
        document.dir = i18n.dir();
    }

    // this is a a function that will construct a route based on the condition of token existence, if no token then the route will be to Unauthorized page
    RouteConstructorWithValidation(path, component, key, exact) {
        // if (this.props.token) {
            return <Route path={path} exact={exact} component={component} key={key} />
        // } else {
        //     return <Route path={path} exact={exact} component={HomePage} key={key} />
        // }
    }

    // this route constructor is for the pages that are not concerned with authorization
    RouteConstructor(path, component, key, exact) {
        return <Route path={path} exact={exact} component={component} key={key} />
    }

    render() {
        const withouthValidationRoutes = noValidationRoutes.map(route => {
            return this.RouteConstructor(route.path, route.component, route.key, route.exact)
        })
        return (

            // <BrowserRouter>
            <Switch>
                <Route path={`/`} exact component={HomePage} key={`/`} />
                {/* {authorizedFinalRoutes.map(route => { return route })} */}
                {withouthValidationRoutes.map(route => { return route })}
            </Switch>
            // </BrowserRouter>

        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    token: state.authentication ? state.authentication.token : ""
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Routes);