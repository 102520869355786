import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n-config/i18n";
import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBTooltip,
} from "mdbreact";
import Check from "../../assets/home/check-bottom.png";
import BackEN from "../../assets/home/back-arrow.svg";
import BackAR from "../../assets/home/back-arrow-ar.svg";
import activeSplitByItems from "../../assets/home/whiteSplitIByItems.svg";
import splitByItems from "../../assets/home/splitByItems.svg";
import activeSplitEvenly from "../../assets/home/whiteSplitEvenly.svg";
import splitEvenly from "../../assets/home/splitEvenly.svg";
import activeSplitCustom from "../../assets/home/whiteSplitCustom.svg";
import splitCustom from "../../assets/home/splitCustom.svg";
import share from "../../assets/home/share-blue.svg";
import arabicShare from "../../assets/home/arabicShare.svg";
import service from "../../assets/home/service.svg";
import people from "../../assets/home/user.svg";
import moneyPaper from "../../assets/home/moneyPaper.svg";
import invoiceBill from "../../assets/home/invoiceBill.svg";
import Splitting from "../../assets/home/splitting.png";
import {
  CONFIG,
  LOGINCONFIG,
  LOGIN_GUEST,
  PARTIALLY_PAY,
  PAY_NOW,
  GET_API_URL,
  TRANSACTION_LOCKED,
} from "../../APIs/index";
import "./SplitTheBill.scss";
import axiosInstance from "../../APIs/axios";
import { removeQueryParameter } from "../../UTILs/functions";
import {
  setCoreMerchantValue,
  setImageUrls,
  setOrderId,
} from "../../Redux/Actions/mediaDirectoryActions";
import { setTokens } from "../../Redux/Actions/authActions";

const queryString = window.location.search;

const urlParams = new URLSearchParams(queryString);

class SplitTheBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splitOption: this.props.splitOption,
      dataInfo: this.props.dataInfo,
      splitTheBillItems: [],
      // isMobile: matchMedia("(max-width: 435px)").matches,
      isMobile: window.innerWidth <= 435,
      payBill: false,
      unavailableSystem: false,
      wrongQR: false,
      showInput: false,
      other: null,
      terminalId: urlParams.get("terminalId")
        ? urlParams.get("terminalId")
        : "",
      recordGuid: urlParams.get("RecordGuid")
        ? urlParams.get("RecordGuid")
        : urlParams.get("recordGuid")
        ? urlParams.get("recordGuid")
        : "",
      totalBill: 500,
      tip: 0,
      viewMenu: false,
      invoiceLinked: false,
      errors: "",
      loading: false,
      languageModal: false,
      countries: [],
      selectedOption: "",
      selectedLanguage: i18n.language,
      splitModal: false,
      showSplittingData: false,
      countPeople: 0,
      maxPeoplePayingFor: 0,
      exceededAmount: false,
      payingTheBillModal: false,
      refreshModal: false,
      amountExceeded: null,
      disabled: false,
      token: this.props.token,
      amountToPay: 0,
      amountInput: null,
      tenPercentTip: 0,
      fiftenPercentTip: 0,
      twentyPercentTip: 0,
      yourShare: 0,
      active: 1,
      languageCode: this.props.languageCode,
      amountLeftToPay: this.props.dataInfo?.amountLeftToPay,
      underDevelopmentModal: false,
    };
    this.addOne = this.addOne.bind(this);
  }

  componentDidMount() {
    this.loginGuest();
    const { dataInfo, amountLeftToPay } = this.state;
    this.setState(
      {
        currency: dataInfo?.currency,
        amountLeft: dataInfo?.totalAmount - amountLeftToPay,
        totalAmountWithVat: dataInfo?.totalAmountWithVat,
        tenPercentTip: this.roundNumber(
          dataInfo?.totalAmountWithVat * (10 / 100)
        ),
        fiftenPercentTip: this.roundNumber(
          dataInfo?.totalAmountWithVat * (15 / 100)
        ),
        twentyPercentTip: this.roundNumber(
          dataInfo?.totalAmountWithVat * (20 / 100)
        ),
        qrValue: dataInfo?.loyaltyQrImage,
        splitTheBillItems: this.props.splitTheBillItems.map(
          (x) => [...this.state.splitTheBillItems, { ...x, quantityAdd: 0 }][0]
        ),
        yourShare:
          amountLeftToPay === 0
            ? dataInfo?.totalAmountWithVat
            : amountLeftToPay,
        amountLeftToPay: amountLeftToPay,
        // amountToPay:
        //   amountLeftToPay === 0
        //     ? parseFloat(dataInfo?.totalAmountWithVat + tip)
        //     : parseFloat(amountLeftToPay + tip),
      },
      () => this.handleTipChange(10)
    );
  }

  loginGuest = () => {
    axiosInstance
      .post(
        `${LOGIN_GUEST}`,
        {
          Identifier: this.props.visitorId,
        },
        LOGINCONFIG
      )
      .then((res) => {
        let { data } = res.data;
        this.setState({
          token: data.accessToken,
        });
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.loginGuest();
        }
        if (err?.response?.data?.message === "Login guest error") {
          this.setState({ unavailableSystem: true });
        }
        this.setState({ errors: err?.response?.data?.message });
      })
      .finally(() => this.setState({ loading: false }));
  };

  paynowSplit = (value) => {
    const currentUrl = window.location.href;
    const updatedUrl = removeQueryParameter(currentUrl, "RecordGuid");
    const cleanedUrl = updatedUrl.split("?")[0]; // Get only the base URL without query parameters
    const { active, amountLeftToPay } = this.state;
    LOGINCONFIG.headers.Authorization = `Bearer ${this.state.token}`;
    LOGINCONFIG.headers["LanguageCode"] = this.props.languageCode;
    const { yourShare, tip, splitOption, amountExceeded } = this.state;
    this.setState({ value: value });
    if (value === "noTip") {
      this.setState({ noTipLoading: true, loading: false });
    } else if (value === "withTip") {
      this.setState({ noTipLoading: false, loading: true });
    }
    const amount =
      amountLeftToPay === this.props.dataInfo?.totalAmountWithVat
        ? this.props.dataInfo?.totalAmountWithVat
        : amountLeftToPay;
    const tipAmount =
      tip == null
        ? 0
        : active === 0
        ? tip
        : active === 1
        ? this.roundNumber(amount * (10 / 100))
        : active === 2
        ? this.roundNumber(amount * (15 / 100))
        : this.roundNumber(amount * (20 / 100));
    let body = {
      TerminalGuid: this.props?.recordGuid,
      SuccessUrl: `${cleanedUrl}success:externalReferenceId=${this.props.recordGuid}`,
      FailedUrl: `${cleanedUrl}failed:externalReferenceId=${this.props.recordGuid}`,
      Amount: yourShare < amount ? yourShare : amount,
      TipAmount:
        value === "noTip"
          ? tipAmount
          : parseFloat(this.roundNumber(amountExceeded)) +
            parseFloat(this.roundNumber(tip)),
      PaymentTypeTag:
        splitOption === 1
          ? "PARTIALLY_ITEMS"
          : splitOption === 2
          ? "PARTIALLY_EVENLY"
          : splitOption === 3
          ? "PARTIALLY_AMOUNT"
          : "",
    };

    if (splitOption === 1) {
      const itemsWithQuantityAdd = this.state.splitTheBillItems
        .filter((item) => item.quantityAdd > 0)
        .map((item) => ({
          RecordGuid: item.recordGuid,
          Quantity: item.quantity,
        }));

      const isQuantityAddLessThanQuantity = this.state.splitTheBillItems.some(
        (item) => item.quantityAdd > 0 && item.quantityAdd < item.quantity
      );

      if (isQuantityAddLessThanQuantity) {
        this.setState({ loading: false, disabled: false });
        alert(
          "Please update the quantity added to be equal to the original quantity."
        );
        return;
      }

      if (itemsWithQuantityAdd.length > 0) {
        body.Items = itemsWithQuantityAdd;
      }
    }

    axiosInstance
      .post(`${PARTIALLY_PAY} `, body, LOGINCONFIG)
      .then((res) => {
        if (res?.data?.success) {
          // this.redirectToPaymentGateway()
          this.setState({ loading: false });
          // Open the redirection URL in a new window or tab
          const redirectionURL = res?.data?.data?.redirectionURL;
          this.props.setOrderId(res?.data?.data?.orderId);

          // Check if the user is on a mobile device
          const isMobile = /Mobi|Android/i.test(navigator.userAgent);

          if (isMobile) {
            // On mobile, try opening the URL in a new window
            const newWindow = window.open(redirectionURL, "_blank");
            if (!newWindow) {
              // If opening in a new window fails, fallback to changing the current location
              window.location.href = redirectionURL;
            }
          } else {
            // On desktop, open the URL in a new tab
            window.open(redirectionURL, "_blank");
          }
        }
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.paynowSplit(value);
        }
        if (err?.response?.status === 409) {
          this.setState(
            {
              loading: false,
              disabled: false,
              message: err?.response?.data?.message,
            },
            () => this.togglePayingTheBillModal()
          );
        } else if (err?.response?.status === 423) {
          this.setState(
            {
              loading: false,
              disabled: false,
              noTipLoading: false,
              message: err?.response?.data?.message,
              partiallyPay: true,
            },
            () => this.toggleSplittingTheModal()
          );
        }
        console.error(
          err?.response ? err?.response?.data?.result?.message : err
        );
      });
  };

  payNow = async () => {
    this.setState({ loading: true, disabled: true });
    const { tip, yourShare, splitOption } = this.state;
    LOGINCONFIG.headers.Authorization = `Bearer ${this.state.token} `;
    LOGINCONFIG.headers["LanguageCode"] = this.props.languageCode;

    const currentUrl = window.location.href;
    const updatedUrl = removeQueryParameter(currentUrl, "RecordGuid");
    const cleanedUrl = updatedUrl.split("?")[0];

    let body = {
      TerminalGuid: this.props.recordGuid,
      Amount: yourShare,
      TipAmount: tip == null ? 0 : tip,
      SuccessUrl: `${cleanedUrl}success:externalReferenceId=${this.props.recordGuid}`,
      FailedUrl: `${cleanedUrl}failed:externalReferenceId=${this.props.recordGuid}`,
      PaymentTypeTag:
        splitOption === 1
          ? "PARTIALLY_ITEMS"
          : splitOption === 2
          ? "PARTIALLY_EVENLY"
          : splitOption === 3
          ? "PARTIALLY_AMOUNT"
          : "",
    };

    // Add the Items array if splitOption indicates partial item payments
    if (splitOption === 1) {
      const itemsWithQuantityAdd = this.state.splitTheBillItems
        .filter((item) => item.quantityAdd > 0)
        .map((item) => ({
          RecordGuid: item.recordGuid,
          Quantity: item.quantity,
        }));

      const isQuantityAddLessThanQuantity = this.state.splitTheBillItems.some(
        (item) => item.quantityAdd > 0 && item.quantityAdd < item.quantity
      );

      if (isQuantityAddLessThanQuantity) {
        this.setState({ loading: false, disabled: false });
        alert(
          "Please update the quantity added to be equal to the original quantity."
        );
        return;
      }

      if (itemsWithQuantityAdd.length > 0) {
        body.Items = itemsWithQuantityAdd;
      }
    }

    await axiosInstance
      .post(`${PARTIALLY_PAY} `, body, LOGINCONFIG)
      .then((res) => {
        if (res?.data?.success) {
          // this.redirectToPaymentGateway()
          this.setState({ loading: false });

          // Open the redirection URL in a new window or tab
          const redirectionURL = res?.data?.data?.redirectionURL;
          this.props.setOrderId(res?.data?.data?.orderId);

          // Check if the user is on a mobile device
          const isMobile = /Mobi|Android/i.test(navigator.userAgent);

          if (isMobile) {
            // On mobile, try opening the URL in a new window
            const newWindow = window.open(redirectionURL, "_blank");
            if (!newWindow) {
              // If opening in a new window fails, fallback to changing the current location
              window.location.href = redirectionURL;
            }
          } else {
            // On desktop, open the URL in a new tab
            window.open(redirectionURL, "_blank");
          }
        }
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.payNow();
        }
        if (err?.response?.status === 400) {
          this.setState(
            {
              loading: false,
              disabled: false,
              message: err?.response?.data?.message,
            },
            () => this.togglePayingTheBillModal()
          );
        }

        if (err?.response?.status === 409) {
          this.setState(
            {
              loading: false,
              disabled: false,
              message: err?.response?.data?.message,
            },
            () => this.togglePayingTheBillModal()
          );
        } else if (err?.response?.status === 423) {
          this.setState(
            {
              loading: false,
              disabled: false,
              message: err?.response?.data?.message,
            },
            () => this.toggleSplittingTheModal()
          );
        }
        console.error(
          err?.response ? err?.response?.data?.result?.message : err
        );
      });
  };

  redirectToPaymentGateway = (amountToPay) => {
    const { value } = this.state;
    const formattedAmount = amountToPay.toFixed(2);
    CONFIG.headers.Authorization = "bearer " + this.props.token;
    axiosInstance
      .post(
        `${PAY_NOW} /${
          this.state.dataInfo?.id
        }/split - payment ? amount = ${formattedAmount}${
          value === "noTip" ? "" : "&isExceededAmountForTip=true"
        } `,
        {},
        CONFIG
      )
      .then((res) => {
        if (res?.data?.data?.paymentUrl !== "") {
          this.setState({
            loading: false,
            noTipLoading: false,
            payingTheBillModal: false,
            disabled: true,
          });
          // Open the redirection URL in a new window or tab
          const redirectionURL = res?.data?.data?.paymentUrl;
          // Check if the user is on a mobile device
          const isMobile = /Mobi|Android/i.test(navigator.userAgent);

          if (isMobile) {
            // On mobile, try opening the URL in a new window
            const newWindow = window.open(redirectionURL, "_blank");
            if (!newWindow) {
              // If opening in a new window fails, fallback to changing the current location
              window.location.href = redirectionURL;
            }
          } else {
            // On desktop, open the URL in a new tab
            window.open(redirectionURL, "_blank");
          }
        }
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.redirectToPaymentGateway(amountToPay);
        }
        console.error(
          err?.response ? err?.response?.data?.result?.message : err
        );
      });
  };

  togglePayBill = () => {
    this.setState({ payBill: !this.state.payBill });
    this.props.goBack();
  };

  toggleSuccessModal = () => {
    this.setState({ successModal: !this.state.successModal });
  };

  toggleunavailableSystemModal = () => {
    this.setState({ unavailableSystem: !this.state.unavailableSystem });
  };

  toggleShowInput = () => {
    this.setState({ showInput: !this.state.showInput, active: "" }, () => {
      document.getElementById("inputRef").focus();
    });
  };

  succesFlow = () => {
    this.toggleSuccessModal();
    setTimeout(() => {
      this.setState({ successModal: false });
    }, 3000);
  };

  handleTipChange = (value) => {
    const { dataInfo, yourShare } = this.state;
    let total = 0;
    // dataInfo.items.forEach((item) => {
    //     total += item.itemPriceWithCharges * item.quantity
    // });
    if (this.state.splitOption === 3) {
    } else {
      this.state.splitTheBillItems.forEach((item) => {
        total +=
          item.quantityAdd === 0
            ? item.itemPriceWithCharges * item?.quantity
            : item.itemPriceWithCharges * item.quantityAdd;
      });
    }

    const totalWithoutTip = total + dataInfo?.vat;

    const yourShareFloat = parseFloat(yourShare);
    const tipFloat = parseFloat(
      this.roundNumber(yourShareFloat * (value / 100))
    );

    // Calculate amountToPay by adding yourShareFloat and tipFloat
    const amountToPay = yourShareFloat + tipFloat;
    this.setState({
      active: value === 10 ? 1 : value === 15 ? 2 : 3,
      showInput: false,
      tip: this.roundNumber(yourShare * (value / 100)),
      tenPercentTip: this.roundNumber(yourShare * (10 / 100)),
      fiftenPercentTip: this.roundNumber(yourShare * (15 / 100)),
      twentyPercentTip: this.roundNumber(yourShare * (20 / 100)),
      other: null,
      totalAmount: totalWithoutTip + totalWithoutTip * (value / 100),
      totalWithoutTip: total,
      amountToPay: this.roundNumber(amountToPay),
    });
  };

  roundNumber = (number) => {
    if (
      this.isDecimal(number) &&
      !isNaN(number) &&
      typeof number === "number"
    ) {
      return number?.toFixed(2);
    } else {
      return number;
    }
  };

  isDecimal(number) {
    return number % 1 !== 0;
  }

  updateAmount = () => {
    const { other, dataInfo, tip, yourShare } = this.state;
    let total = 0;
    // dataInfo.items.forEach((item) => {
    //     total += item.itemPriceWithCharges * item.quantity
    // });
    this.state.splitTheBillItems.forEach((item) => {
      total += item.itemPriceWithCharges * item.quantityAdd;
    });
    if (other === "") {
      this.setState({
        amountToPay: parseFloat(yourShare + tip),
        totalAmount: parseFloat(this.state.amountInput + other),
      });
    } else {
      this.setState({
        amountToPay:
          this.state.splitOption === 3
            ? parseFloat(this.state.amountInput) + other === ""
              ? 0 + tip
              : parseInt(other) + parseFloat(yourShare)
            : parseFloat(yourShare) + parseFloat(tip),
        totalAmount:
          this.state.splitOption === 3
            ? parseFloat(this.state.amountInput) + parseInt(other)
            : total + dataInfo?.vat + parseInt(other),
      });
    }
  };

  getTotalWithoutTip = () => {
    const { dataInfo } = this.state;
    let total = 0;
    dataInfo.items.forEach((item) => {
      total += item.itemPriceWithCharges * item.quantity;
    });

    const totalWithoutTip = total + dataInfo?.vat;
    this.setState({ totalAmount: totalWithoutTip });
  };

  toggleSplitModal = () => {
    this.setState({ splitModal: !this.state.splitModal });
  };

  openSplitFeature = (splitOption) => {
    this.setState({ showSplittingData: true, splitOption: splitOption });
  };

  changeActive = (value) => {
    const { dataInfo, amountInput, amountLeftToPay } = this.state;
    this.setState(
      {
        splitOption: value,
        disabled: false,
        yourShare:
          amountInput == null
            ? amountLeftToPay === 0
              ? dataInfo?.totalAmountWithVat
              : amountLeftToPay
            : amountLeftToPay === 0
            ? dataInfo?.totalAmountWithVat
            : amountLeftToPay,
        tip: 0,
        amountToPay:
          amountLeftToPay === 0
            ? dataInfo?.totalAmountWithVat
            : amountLeftToPay, //this.roundNumber(parseFloat(yourShare + tip)),
        amountInput: null,
        countPeople: 0,
        maxPeoplePayingFor: 0,
        active: 1,
        showInput: false,
        other: null,
      },
      () => this.handleTipChange(10)
    );
  };

  handleChange = (e) => {
    const { dataInfo, tip, active, amountLeftToPay } = this.state;

    // Parse dataInfo?.amountLeftToPay and tip as numbers
    const parsedAmountLeftToPay = parseFloat(amountLeftToPay) || 0;
    const parsedTip = parseFloat(tip) || 0;
    const amountInput = e.target.value.replace(/\D/, "");

    const yourShare =
      e.target.value == null || e.target.value === ""
        ? parsedAmountLeftToPay === 0
          ? parseFloat(dataInfo?.totalAmountWithVat)
          : parsedAmountLeftToPay
        : parseFloat(amountInput);
    const tipValue =
      active === 1 ? 10 : active === 2 ? 15 : active === 3 ? 20 : amountInput;
    const yourShareFloat = parseFloat(yourShare);
    const tipFloat = parseFloat(
      this.roundNumber(yourShareFloat * (tipValue / 100))
    );
    // Calculate amountToPay by adding yourShareFloat and tipFloat
    const amountToPay = yourShareFloat + tipFloat;
    this.setState(
      {
        [e.target.name]: amountInput,
        yourShare: yourShareFloat,
        amountToPay: amountToPay,
        // this.setState({
        //     [e.target.name]: amountInput,
        //     yourShare: e.target.value == null || e.target.value == "" ?
        //         parsedAmountLeftToPay === 0 ? parseFloat(dataInfo?.totalAmountWithVat) : parsedAmountLeftToPay :
        //         parseFloat(amountInput),
        //     amountToPay: e.target.value == null || e.target.value == "" ?
        //         parsedAmountLeftToPay === 0 ? parseFloat(dataInfo?.totalAmountWithVat) + parsedTip :
        //             parsedAmountLeftToPay + parsedTip
        //         : isNaN(e.target.value) ? 0 : parseFloat(e.target.value) + parsedTip
      },
      () => this.updateTip()
    );
  };

  updateTip = () => {
    const { yourShare, active, tip } = this.state;
    this.setState({
      tenPercentTip: this.roundNumber(yourShare * (10 / 100)),
      fiftenPercentTip: this.roundNumber(yourShare * (15 / 100)),
      twentyPercentTip: this.roundNumber(yourShare * (20 / 100)),
      tip:
        active === 0
          ? tip
          : active === 1
          ? this.roundNumber(yourShare * (10 / 100))
          : active === 2
          ? this.roundNumber(yourShare * (15 / 100))
          : this.roundNumber(yourShare * (20 / 100)),
    });
  };

  handleShare = () => {
    this.setState({ underDevelopmentModal: true });

    // if (navigator.share) {
    //   navigator
    //     .share({
    //       title: "invoice",
    //       text: "invoice",
    //       url: `${GET_API_URL}?PaymentGuid=${this.props.orderId}`,
    //     })
    //     .then(() => console.log("Shared successfully."))
    //     .catch((error) => console.log("Error sharing:", error));
    // } else {
    //   console.log("Web Share API not supported.");
    // }
  };

  reduceOne = (value) => {
    const { maxPeoplePayingFor } = this.state;
    if (value === "countPeople") {
      this.setState(
        (prevState) => ({
          countPeople: prevState.countPeople - 1,
          maxPeoplePayingFor:
            prevState.countPeople - 1 < maxPeoplePayingFor
              ? prevState.countPeople - 1
              : maxPeoplePayingFor,
        }),
        () =>
          this.updateYourShare(
            this.state.countPeople,
            this.state.maxPeoplePayingFor
          )
      );
    } else if (value === "maxPeoplePayingFor") {
      this.setState(
        (prevState) => ({
          maxPeoplePayingFor: prevState.maxPeoplePayingFor - 1,
        }),
        () =>
          this.updateYourShare(
            this.state.countPeople,
            this.state.maxPeoplePayingFor
          )
      );
    }
  };

  addOne(value) {
    if (value === "countPeople") {
      this.setState(
        (prevState) => ({
          countPeople: prevState.countPeople + 1,
        }),
        () =>
          this.updateYourShare(
            this.state.countPeople,
            this.state.maxPeoplePayingFor
          )
      );
    } else if (value === "maxPeoplePayingFor") {
      this.setState(
        (prevState) => ({
          maxPeoplePayingFor:
            prevState.maxPeoplePayingFor + 1 <= this.state.countPeople
              ? prevState.maxPeoplePayingFor + 1
              : prevState.maxPeoplePayingFor,
        }),
        () =>
          this.updateYourShare(
            this.state.countPeople,
            this.state.maxPeoplePayingFor
          )
      );
    }
  }

  updateYourShare = (countPeople, maxPeoplePayingFor) => {
    const { dataInfo, active, other, amountLeftToPay } = this.state;
    let value =
      active === 1 ? 10 : active === 2 ? 15 : active === 3 ? 20 : other;
    const totalAmount =
      amountLeftToPay === 0 ? dataInfo?.totalAmountWithVat : amountLeftToPay;
    const divideEvenly = countPeople === 0 ? 1 : totalAmount / countPeople;

    // Format divideEvenly
    const formattedDivideEvenly = this.formatNumber(divideEvenly);
    const amountToPay =
      formattedDivideEvenly === "1"
        ? totalAmount
        : formattedDivideEvenly * maxPeoplePayingFor;
    const yourShareFloat = parseFloat(amountToPay);
    const tipFloat = parseFloat(this.roundNumber(amountToPay * (value / 100)));

    // Calculate amountToPay by adding yourShareFloat and tipFloat
    let amountToPayFloat = yourShareFloat + tipFloat;
    this.setState(
      {
        amountToPay: this.roundNumber(amountToPayFloat),
        yourShare: this.roundNumber(yourShareFloat),
      },
      () => this.updateTip()
    );
  };

  formatNumber = (value) => {
    if (value % 1 !== 0) {
      return value.toFixed(2);
    } else {
      return value.toString();
    }
  };

  decimalFormat = (value) => {
    const re = /^\d+\.\d{3,}$/;
    // if value is not blank, then test the regex

    if (re.test(value)) {
      return value.toFixed(2);
    } else {
      return value;
    }
  };

  addItems = (items, index) => {
    const { active, other } = this.state;

    let allAmounts = 0;
    let value =
      active === 1 ? 10 : active === 2 ? 15 : active === 3 ? 20 : other;
    let newSplitTheBillItems = [...this.state.splitTheBillItems];
    newSplitTheBillItems[index] = {
      ...items,
      quantityAdd: parseFloat(items.quantityAdd) + 1,
    };
    newSplitTheBillItems.forEach(
      (x) =>
        (allAmounts =
          allAmounts +
          parseFloat(x?.quantityAdd) * parseFloat(x?.itemPriceWithCharges))
    );
    const yourShareFloat = parseFloat(allAmounts);
    const tipFloat = parseFloat(this.roundNumber(allAmounts * (value / 100)));

    // Calculate amountToPay by adding yourShareFloat and tipFloat
    let amountToPay = yourShareFloat + tipFloat;
    this.setState(
      (prevState) => ({
        splitTheBillItems: newSplitTheBillItems,
        yourShare: allAmounts || 0,
        amountToPay: amountToPay || 0,
      }),
      () => this.updateTip()
    );
  };

  reduceItems = (items, index) => {
    const { totalAmountWithVat, active, other, amountLeftToPay } = this.state;
    let allAmounts = 0;
    let value =
      active === 1 ? 10 : active === 2 ? 15 : active === 3 ? 20 : other;
    let newSplitTheBillItems = [...this.state.splitTheBillItems];
    newSplitTheBillItems[index] = {
      ...items,
      quantityAdd: parseFloat(items.quantityAdd) - 1,
    };
    newSplitTheBillItems.forEach(
      (x) =>
        (allAmounts =
          allAmounts +
          parseFloat(x?.quantityAdd) * parseFloat(x?.itemPriceWithCharges))
    );

    const yourShareFloat =
      allAmounts === 0
        ? amountLeftToPay > 0
          ? amountLeftToPay
          : totalAmountWithVat
        : parseFloat(allAmounts);
    const tipFloat =
      allAmounts === 0
        ? parseFloat(this.roundNumber(yourShareFloat * (value / 100)))
        : parseFloat(this.roundNumber(allAmounts * (value / 100)));

    // Calculate amountToPay by adding yourShareFloat and tipFloat
    let amountToPay = yourShareFloat + tipFloat;

    this.setState(
      (prevState) => ({
        splitTheBillItems: newSplitTheBillItems,
        yourShare: yourShareFloat,
        amountToPay: amountToPay,
      }),
      () => this.updateTip()
    );
  };

  toggleExeededAmount = () => {
    const { amountInput, dataInfo, splitOption, yourShare, amountLeftToPay } =
      this.state;
    if (splitOption === 3) {
      if (amountLeftToPay > 0 && amountInput > amountLeftToPay) {
        this.setState({
          exceededAmount: !this.state.exceededAmount,
          amountExceeded: amountInput - amountLeftToPay,
        });
      } else if (
        amountLeftToPay === 0 &&
        dataInfo.totalAmountWithVat < amountInput
      ) {
        this.setState({
          exceededAmount: !this.state.exceededAmount,
          amountExceeded: this.state.amountInput - dataInfo.totalAmount,
        });
      } else {
        this.checkTransactionLock(this.state.token);
        // this.payNow();
      }
    }
    if (splitOption === 1 || splitOption === 2) {
      if (amountLeftToPay > 0 && yourShare > amountLeftToPay) {
        this.setState({
          exceededAmount: !this.state.exceededAmount,
          amountExceeded: yourShare - amountLeftToPay,
        });
      } else if (
        amountLeftToPay === 0 &&
        dataInfo.totalAmountWithVat < yourShare
      ) {
        this.setState({
          exceededAmount: !this.state.exceededAmount,
          amountExceeded: this.state.yourShare - dataInfo.totalAmount,
        });
      } else {
        this.checkTransactionLock(this.state.token);
        // this.payNow();
      }
    }
  };

  checkTransactionLock = async (token) => {
    const { recordGuid, languageCode, totalAmountWithVat, amountLeftToPay } =
      this.state;
    const checkAmountPaid = this.roundNumber(
      totalAmountWithVat - amountLeftToPay
    );

    LOGINCONFIG.headers.Authorization = `Bearer ${token}`;
    LOGINCONFIG.headers["LanguageCode"] = languageCode;

    await axiosInstance
      .get(`${TRANSACTION_LOCKED}?Reference=${recordGuid}`, LOGINCONFIG)
      .then((res) => {
        this.setState({ hasOpenInvoice: false });
        if (res?.data?.message === "Success") {
          if (res?.data?.data?.isThereOpenSession === false) {
            const amountPaidFromServer = this.roundNumber(
              res?.data?.data?.amountPaid
            );
            if (
              this.roundNumber(totalAmountWithVat) ===
              this.roundNumber(
                parseFloat(amountPaidFromServer) + parseFloat(amountLeftToPay)
              )
            ) {
              this.setState(
                {
                  amountPaid: amountPaidFromServer,
                  checkAmountPaid: checkAmountPaid,
                },
                () => this.payNow()
              );
            } else {
              this.setState(
                {
                  amountPaid: amountPaidFromServer,
                  checkAmountPaid: checkAmountPaid,
                },
                () =>
                  this.toggleRefreshModal(checkAmountPaid, amountPaidFromServer)
              );
            }
          } else if (res?.data?.data?.isThereOpenSession === true) {
            this.setState(
              { message: "Your table mate is already paying the bill" },
              () => this.toggleSplittingTheModal()
            );
          }
        }
      })
      .catch((err) => {
        if (err === "error: 401") {
          this.checkTransactionLock(token);
        }
        this.setState({
          loading: false,
          disabled: false,
          loadingHasOpenInvoice: false,
        });
        if (err?.response?.status === 400) {
          this.setState({
            loading: false,
            disabled: false,
            message: err?.response?.data?.message,
            loadingHasOpenInvoice: false,
            payingTheBillModal: true,
          });
        } else {
          this.setState({
            loading: false,
            disabled: false,
            message: err?.response?.data?.exception,
            splittingModal: true,
          });
        }
      });
  };

  togglePayingTheBillModal = () => {
    this.setState({ payingTheBillModal: !this.state.payingTheBillModal });
  };

  toggleRefreshModal = (checkAmountPaid, amountPaid) => {
    this.setState({
      refreshModal: !this.state.refreshModal,
      checkAmountPaid: checkAmountPaid,
      amountPaid: amountPaid,
    });
  };

  updateAmountLeftToPay = () => {
    const { amountPaid, totalAmountWithVat } = this.state;
    let updatedAmountLeftToPay = this.roundNumber(
      totalAmountWithVat - amountPaid
    );
    this.setState({ amountLeftToPay: updatedAmountLeftToPay }, () =>
      this.toggleRefreshModal()
    );
  };

  removePercentage = () => {
    const { yourShare } = this.state;
    this.setState({
      amountToPay: parseInt(yourShare),
      other: null,
      showInput: true,
      active: 0,
      tip: 0,
    });
  };

  toggleDiscardTheModal = () => {
    this.props.toggleDiscardTheModal();
  };

  toggleSplittingTheModal = () => {
    this.setState({
      splittingModal: !this.state.splittingModal,
      loading: false,
    });
  };

  render() {
    const {
      loading,
      splitOption,
      amountToPay,
      dataInfo,
      other,
      currency,
      tip,
      countPeople,
      maxPeoplePayingFor,
      noTipLoading,
      noTipDisabled,
      tipDisabled,
      disabled,
      splitTheBillItems,
      totalAmountWithVat,
      tenPercentTip,
      fiftenPercentTip,
      twentyPercentTip,
      yourShare,
      message,
      amountLeftToPay,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        <section
          className={`${
            i18n.language === "en"
              ? " left-to-right split-the-bill "
              : " right-to-left split-the-bill"
          }`}
        >
          <div className="centered-div">
            <MDBContainer>
              <MDBRow md="12">
                <MDBCol style={{ marginLeft: "-25px" }} size="4">
                  <span>
                    <img
                      alt="Back"
                      src={i18n.language === "en" ? BackEN : BackAR}
                      className="d-flex text-left split-back cursor-pointer"
                      onClick={this.toggleDiscardTheModal}
                    />
                  </span>
                </MDBCol>
              </MDBRow>
              <MDBRow md="12">
                <MDBCol size="7">
                  <p className=" d-flex text-left split-bill">
                    {t("Split The Bill")}
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow
                md="12"
                className={`${
                  i18n.language === "en" ? " left-to-right " : " right-to-left "
                }`}
              >
                {this.props.showPartiallyItemsButton && (
                  <MDBCol size="4" onClick={() => this.changeActive(1)}>
                    <MDBCard
                      className={
                        splitOption === 1
                          ? `${
                              i18n.language === "en"
                                ? " active-splitOption "
                                : "active-splitOption left-to-right "
                            }`
                          : `${
                              i18n.language === "en"
                                ? " inactive-splitOption "
                                : "inactive-splitOption left-to-right "
                            }`
                      }
                    >
                      <MDBRow size="12" end></MDBRow>
                      <MDBRow
                        size="12"
                        className="h-100 d-flex align-items-center justify-content-center "
                        center
                      >
                        {/* <i className="far fa-check-circle"></i> */}
                        {splitOption === 1 ? (
                          <i className="far fa-check-circle"></i>
                        ) : (
                          <i className="far fa-circle"></i>
                        )}
                        <img
                          src={
                            splitOption === 1
                              ? activeSplitByItems
                              : splitByItems
                          }
                          alt="Split By items"
                        ></img>
                        <span
                          className={
                            splitOption === 1
                              ? " text-center text-white ml-2 splitOptions"
                              : " text-center ml-2 splitOptions"
                          }
                        >
                          {t("Items")}
                        </span>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                )}
                {this.props.showPartiallyEvenlyButton && (
                  <MDBCol size="4" onClick={() => this.changeActive(2)}>
                    <MDBCard
                      className={
                        splitOption === 2
                          ? `${
                              i18n.language === "en"
                                ? " active-splitOption "
                                : "active-splitOption left-to-right "
                            }`
                          : `${
                              i18n.language === "en"
                                ? " inactive-splitOption "
                                : "inactive-splitOption left-to-right "
                            }`
                      }
                    >
                      <MDBRow
                        size="12"
                        className="h-100  d-flex align-items-center justify-content-center "
                        center
                      >
                        {splitOption === 2 ? (
                          <i className="far fa-check-circle"></i>
                        ) : (
                          <i className="far fa-circle"></i>
                        )}
                        <img
                          src={
                            splitOption === 2 ? activeSplitEvenly : splitEvenly
                          }
                          alt="Split Evenly"
                        ></img>
                        <span
                          className={
                            splitOption === 2
                              ? "text-white ml-2 splitOptions"
                              : " text-center ml-2 splitOptions"
                          }
                        >
                          {t("Evenly")}
                        </span>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                )}
                {this.props?.showPartiallyAmountButton && (
                  <MDBCol size="4" onClick={() => this.changeActive(3)}>
                    <MDBCard
                      className={
                        splitOption === 3
                          ? `${
                              i18n.language === "en"
                                ? " active-splitOption "
                                : "active-splitOption left-to-right "
                            }`
                          : `${
                              i18n.language === "en"
                                ? " inactive-splitOption "
                                : "inactive-splitOption left-to-right "
                            }`
                      }
                    >
                      <MDBRow
                        size="12"
                        className="h-100  d-flex align-items-center justify-content-center"
                        center
                      >
                        {splitOption === 3 ? (
                          <i className="far fa-check-circle"></i>
                        ) : (
                          <i className="far fa-circle"></i>
                        )}
                        <img
                          src={
                            splitOption === 3 ? activeSplitCustom : splitCustom
                          }
                          alt="Split By Amount"
                        ></img>
                        <span
                          className={
                            splitOption === 3
                              ? "text-white ml-2 splitOptions"
                              : " text-center ml-2 splitOptions"
                          }
                        >
                          {t("Amount")}
                        </span>
                      </MDBRow>
                    </MDBCard>
                  </MDBCol>
                )}
              </MDBRow>
              <MDBRow md="12" className="mt-4">
                <MDBCol md="8">
                  <p className=" d-flex text-left option-desc">
                    {splitOption === 1 && t("Pay For Your Items")}
                    {splitOption === 2 && t("Divide The Bill Evenly")}
                    {splitOption === 3 && t("Pay A Custom Amount")}:
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <div className="top-bar">
              <MDBRow center className="mt-4 mb-0">
                {/* <MDBCol size="10" className="text-center"> */}
                {/* <span
                    className={
                      i18n.language === "en"
                        ? "title-card"
                        : "arabic-title-card"
                    }
                  >
                    {t(this.state.dataInfo?.title)}
                  </span> */}

                <MDBTooltip tag="span" placement="top" domElement>
                  <span
                    className={
                      i18n.language === "en"
                        ? "title-card parentCell"
                        : "arabic-title-card parentCell"
                    }
                  >
                    {t(this.state.dataInfo?.title)}
                  </span>
                  <span> {t(this.state.dataInfo?.title)}</span>
                </MDBTooltip>

                <img
                  src={`${i18n.language === "en" ? share : arabicShare}`}
                  alt="Share Icon"
                  onClick={this.handleShare}
                  className={`${
                    i18n.language === "en" ? "share-icon" : "arabic-share-icon"
                  }`}
                ></img>
                {/* </MDBCol> */}
              </MDBRow>
              <MDBRow center className="mb-3">
                <MDBTooltip tag="span" placement="top" domElement>
                  <span className="title-desc parentCell">
                    {t(this.state.dataInfo?.description)}
                  </span>
                  <span> {t(this.state.dataInfo?.description)}</span>
                </MDBTooltip>

                {/* <MDBCol size="10" className="text-center">
                  <p className="title-desc">
                    {t(this.state.dataInfo?.description)}
                  </p>
                </MDBCol> */}
              </MDBRow>
            </div>
            {splitOption === 1 && (
              <div className="body-bar">
                <div className="pt-3"></div>
                {splitTheBillItems?.map((x, index) => {
                  return (
                    <>
                      <MDBContainer key={index}>
                        <MDBRow
                          size="12"
                          className=" align-items-center justify-content-center m-0"
                        >
                          <MDBCol
                            size="5"
                            className="quantity text-right d-flex"
                          >
                            {/* <MDBRow size="12" > */}
                            <MDBCol size="2">{x.quantity}</MDBCol>
                            <MDBCol className="text-left" size="10">
                              <p className="food-names mb-0">{t(x?.name)}</p>
                              {/* <p className='ingredientsDesktop mb-0'>{t(x?.description)}</p> */}
                            </MDBCol>
                            {/* </MDBRow> */}
                          </MDBCol>
                          <MDBCol size="7" className="d-flex">
                            <MDBRow
                              size="12"
                              className={` align-items-center justify-content-center  ${
                                i18n.language === "ar" ? "ml-1" : "  mr-1"
                              }`}
                            >
                              <MDBCol
                                size="5"
                                className={`${
                                  i18n.language === "en"
                                    ? "right-to-left text-left"
                                    : " left-to-right  text-right"
                                }`}
                              >
                                <span className="food-names">
                                  {" "}
                                  {dataInfo?.currency === "USD"
                                    ? "$"
                                    : dataInfo?.currency}{" "}
                                  {x?.itemPrice || 0}
                                </span>
                              </MDBCol>
                              <MDBCol
                                size="2"
                                className={`${
                                  i18n.language === "en" ? " pl-0  " : "pr-0 "
                                }`}
                              >
                                <MDBBtn
                                  className="minus-btn"
                                  disabled={x.quantityAdd === 0 ? true : false}
                                  onClick={() => this.reduceItems(x, index)}
                                >
                                  <i className="fas fa-minus"></i>
                                </MDBBtn>
                              </MDBCol>
                              <MDBCol size="2">
                                <span className="quantity-add">
                                  {x.quantityAdd}
                                </span>
                              </MDBCol>
                              <MDBCol
                                size="2"
                                className={`${
                                  i18n.language === "en" ? " pl-0   " : "pr-0 "
                                }`}
                              >
                                {x.quantityAdd === x?.quantity ? (
                                  <MDBBtn className="check-btn">
                                    <i className="fas fa-check d-grid"></i>
                                  </MDBBtn>
                                ) : (
                                  <MDBBtn
                                    className="plus-btn"
                                    onClick={() => this.addItems(x, index)}
                                  >
                                    <i className="fas fa-plus"></i>
                                  </MDBBtn>
                                )}
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </>
                  );
                })}
              </div>
            )}

            {splitOption === 2 && (
              <div className="body-bar">
                <div className="pt-3"></div>
                <>
                  <MDBContainer>
                    <MDBRow
                      size="12"
                      className=" align-items-center justify-content-center"
                    >
                      <MDBCol size="8" className="quantity text-right d-flex">
                        {/* <MDBRow size="12" > */}
                        <MDBCol size="1">
                          <img src={service} alt="restaurant service"></img>
                        </MDBCol>
                        <MDBCol
                          className={`${
                            i18n.language === "en"
                              ? " left-to-right "
                              : " right-to-left "
                          }`}
                          size="11"
                        >
                          <span
                            className={`${
                              i18n.language === "en"
                                ? " food-names mb-0 float-left"
                                : "food-names mb-0 text-left"
                            }`}
                          >
                            {t("People On Your Table")}
                          </span>
                        </MDBCol>
                        {/* </MDBRow> */}
                      </MDBCol>
                      <MDBCol size="4" className="d-flex">
                        <MDBRow
                          size="12"
                          className={`align-items-center justify-content-center ${
                            i18n.language === "en" ? "  mr-2" : " ml-2 "
                          }`}
                        >
                          {/* <MDBCol size="4"> */}
                          {/* <span className='food-names'> {dataInfo?.currency==="USD"?"$":dataInfo?.currency} {(x?.quantity || 0) * (x?.itemPriceWithCharges || 0).toFixed(2)}</span> */}
                          {/* </MDBCol> */}
                          <MDBCol
                            size="3"
                            className={`${
                              i18n.language === "en"
                                ? " left-to-right pl-0 "
                                : " right-to-left pr-0"
                            }`}
                          >
                            <MDBBtn
                              className="minus-btn"
                              disabled={countPeople === 0 ? true : false}
                              onClick={() => this.reduceOne("countPeople")}
                            >
                              <i className="fas fa-minus"></i>
                            </MDBBtn>
                          </MDBCol>
                          <MDBCol size="3">
                            <span
                              className={`${
                                i18n.language === "en"
                                  ? "nbrOfPeople"
                                  : "arabiNnbrOfPeople"
                              }`}
                            >
                              {countPeople}
                            </span>
                          </MDBCol>
                          <MDBCol
                            size="3"
                            className={`${
                              i18n.language === "en"
                                ? " left-to-right pl-0 "
                                : " right-to-left pr-0"
                            }`}
                          >
                            <MDBBtn
                              className="plus-btn"
                              onClick={() => this.addOne("countPeople")}
                            >
                              <i className="fas fa-plus"></i>
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow
                      size="12"
                      className=" align-items-center justify-content-center"
                    >
                      <MDBCol size="8" className="quantity text-right d-flex">
                        <MDBCol size="1">
                          <img src={people} alt="restaurant service"></img>
                        </MDBCol>
                        <MDBCol size="11">
                          <span
                            className={`${
                              i18n.language === "en"
                                ? " food-names mb-0 float-left"
                                : "food-names mb-0 text-left"
                            }`}
                          >
                            {t("People You Are Paying For")}
                          </span>
                        </MDBCol>
                      </MDBCol>
                      <MDBCol size="4" className="d-flex">
                        <MDBRow
                          size="12"
                          className={`${
                            i18n.language === "en"
                              ? " align-items-center justify-content-center mr-2"
                              : "align-items-center justify-content-center ml-2 "
                          }`}
                        >
                          <MDBCol
                            size="3"
                            className={`${
                              i18n.language === "en"
                                ? " left-to-right pl-0 "
                                : " right-to-left pr-0"
                            }`}
                          >
                            <MDBBtn
                              className="minus-btn"
                              disabled={maxPeoplePayingFor === 0 ? true : false}
                              onClick={() =>
                                this.reduceOne("maxPeoplePayingFor")
                              }
                            >
                              <i className="fas fa-minus"></i>
                            </MDBBtn>
                          </MDBCol>
                          <MDBCol size="3">
                            <span className="nbrOfPeople">
                              {maxPeoplePayingFor}
                            </span>
                          </MDBCol>
                          <MDBCol
                            size="3"
                            className={`${
                              i18n.language === "en"
                                ? " left-to-right pl-0 "
                                : " right-to-left pr-0"
                            }`}
                          >
                            <MDBBtn
                              className="plus-btn"
                              onClick={() => this.addOne("maxPeoplePayingFor")}
                            >
                              <i className="fas fa-plus"></i>
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </>
              </div>
            )}

            {splitOption === 3 && (
              <div className="body-bar">
                <MDBContainer>
                  <MDBInput
                    value={this.state.amountInput}
                    name="amountInput"
                    className={`${
                      i18n.language === "en"
                        ? "amount-input pl-2"
                        : "amount-input pr-2"
                    }`}
                    maxLength={3}
                    placeHolder={t("Please enter Amount")}
                    onChange={this.handleChange}
                  ></MDBInput>
                </MDBContainer>
              </div>
            )}

            <div className="bottom-bar">
              <MDBContainer>
                <div className="pt-3"></div>
                <MDBRow className="mt-3">
                  <MDBCol size="7">
                    <p
                      className="amountLeftToPay d-flex"
                      style={{
                        paddingLeft: i18n.language === "en" ? "30px" : "0",
                        paddingRight: i18n.language === "ar" ? "20px" : "0",
                      }}
                    >
                      {t("Amount Left To Pay")}
                    </p>
                  </MDBCol>
                  <MDBCol
                    size="5"
                    className="d-flex justify-content-end"
                    style={{
                      paddingRight: i18n.language === "en" ? "36px" : "0",
                      paddingLeft: i18n.language !== "en" ? "36px" : "0",
                    }}
                  >
                    <p
                      className={`${
                        i18n.language === "en" ? "total" : "total left-to-right"
                      }`}
                    >
                      {dataInfo?.currency === "USD" ? "$" : dataInfo?.currency}{" "}
                      {amountLeftToPay}
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="7" className="align-items-start d-flex" middle>
                    <p
                      style={{
                        paddingLeft: i18n.language === "en" ? "30px" : "0",
                        paddingRight: i18n.language === "ar" ? "20px" : "0",
                      }}
                      className="total"
                    >
                      {t("Total Bill")}
                    </p>
                  </MDBCol>
                  <MDBCol
                    size="5"
                    className="d-flex justify-content-end"
                    style={{
                      paddingRight: i18n.language === "en" ? "36px" : "0",
                      paddingLeft: i18n.language !== "en" ? "36px" : "0",
                    }}
                  >
                    <p
                      className={`${
                        i18n.language === "en" ? "total" : "total left-to-right"
                      }`}
                    >
                      {dataInfo?.currency === "USD" ? "$" : dataInfo?.currency}{" "}
                      {totalAmountWithVat}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </div>

          <MDBRow center className="mt-3 test">
            <p className="swipe">{t("Swipe Down To Refresh")}</p>
          </MDBRow>
          <MDBRow
            center
            className={`${
              i18n.language === "en" ? ` left-to-right  ` : ` right-to-left`
            }`}
          >
            <p
              className="split-question"
              style={{
                marginRight: `${i18n.language === "en" ? "22vw" : ""}`,
                marginLeft: `${i18n.language !== "en" ? "22vw" : ""}`,
              }}
            >
              {t("Would you like to add a tip?")}
            </p>
          </MDBRow>
          {currency === "USD" ? (
            <MDBRow className="m-2 justify-content-center">
              <MDBCard
                onClick={() => this.handleTipChange(10)}
                className={
                  this.state.active === 1
                    ? `mini-cards-active ${
                        i18n.language === "en" ? "  " : " left-to-right"
                      }`
                    : `mini-cards ${
                        i18n.language === "en" ? "  " : " left-to-right"
                      }`
                }
              >
                <MDBRow className="d-flex justify-content-center">
                  <i class="far fa-thumbs-up"></i>
                  <div className="gridDiv">
                    <span className="text fs-6">10%</span>
                    <span className="fs-11">
                      {tenPercentTip} {currency}{" "}
                    </span>
                  </div>
                </MDBRow>
              </MDBCard>
              <MDBCard
                onClick={() => this.handleTipChange(15)}
                className={
                  this.state.active === 2
                    ? ` mini-cards-active ${
                        i18n.language === "en"
                          ? " ml-1 "
                          : " mr-1 left-to-right"
                      }`
                    : ` mini-cards ${
                        i18n.language === "en" ? "ml-1 " : "mr-1  left-to-right"
                      }`
                }
              >
                <MDBRow className="d-flex justify-content-center">
                  <i class="far fa-smile"></i>
                  <div className="gridDiv">
                    <span className="text fs-6">15%</span>
                    <span className="fs-11">
                      {fiftenPercentTip} {currency}{" "}
                    </span>
                  </div>
                </MDBRow>
              </MDBCard>
              <MDBCard
                onClick={() => this.handleTipChange(20)}
                className={
                  this.state.active === 3
                    ? `mini-cards-active ${
                        i18n.language === "en"
                          ? " ml-1 "
                          : " mr-1 left-to-right"
                      }`
                    : `mini-cards ${
                        i18n.language === "en"
                          ? "ml-1  "
                          : "mr-1  left-to-right"
                      }`
                }
              >
                <MDBRow className="d-flex justify-content-center">
                  <i class="far fa-grin"></i>
                  <div className="gridDiv">
                    <span className="text fs-6">20%</span>
                    <span className="fs-11">
                      {twentyPercentTip} {currency}{" "}
                    </span>
                  </div>
                </MDBRow>
              </MDBCard>
            </MDBRow>
          ) : (
            <MDBRow className="m-2 justify-content-center">
              <MDBCard
                onClick={() => this.handleTipChange(10)}
                className={
                  this.state.active === 1
                    ? ` mini-cards-active  ${
                        i18n.language === "en" ? "" : " left-to-right"
                      }`
                    : `mini-cards ${
                        i18n.language === "en" ? "  " : " left-to-right"
                      }`
                }
              >
                <MDBRow
                  center
                  style={{
                    display: this.state.active === 1 ? "grid" : "flex",
                  }}
                >
                  <div
                    style={{ marginTop: this.state.active === 1 ? "-5px" : "" }}
                  >
                    <i class="far fa-thumbs-up"></i>
                    {this.state.active === 1 && (
                      <span className="text fs-6">10%</span>
                    )}
                  </div>
                  {this.state.active === 1 ? (
                    <span className="fs-11">
                      {tip}
                      {currency}{" "}
                    </span>
                  ) : (
                    <span className="total ml-1">10%</span>
                  )}
                </MDBRow>
              </MDBCard>
              <MDBCard
                onClick={() => this.handleTipChange(15)}
                className={
                  this.state.active === 2
                    ? `${
                        i18n.language === "en"
                          ? "mini-cards-active ml-1 "
                          : "mini-cards-active mr-1 left-to-right"
                      }`
                    : `${
                        i18n.language === "en"
                          ? "ml-1 mini-cards "
                          : "mr-1 mini-cards  left-to-right"
                      }`
                }
              >
                <MDBRow
                  center
                  style={{
                    display: this.state.active === 2 ? "grid" : "flex",
                  }}
                >
                  <div
                    style={{ marginTop: this.state.active === 2 ? "-5px" : "" }}
                  >
                    <i class="far fa-smile"></i>
                    {this.state.active === 2 && (
                      <span className="text fs-6">15%</span>
                    )}
                  </div>
                  {this.state.active === 2 ? (
                    <span className="fs-11">
                      {tip}
                      {currency}{" "}
                    </span>
                  ) : (
                    <span className="total ml-1">15%</span>
                  )}
                </MDBRow>
              </MDBCard>
              <MDBCard
                onClick={() => this.handleTipChange(20)}
                className={
                  this.state.active === 3
                    ? `${
                        i18n.language === "en"
                          ? "mini-cards-active ml-1 "
                          : "mini-cards-active mr-1 left-to-right"
                      }`
                    : `${
                        i18n.language === "en"
                          ? "ml-1 mini-cards "
                          : "mr-1 mini-cards  left-to-right"
                      }`
                }
              >
                <MDBRow
                  center
                  style={{
                    display: this.state.active === 3 ? "grid" : "flex",
                  }}
                >
                  <div
                    style={{ marginTop: this.state.active === 3 ? "-5px" : "" }}
                  >
                    <i class="far fa-grin"></i>
                    {this.state.active === 3 && (
                      <span className="text fs-6">20%</span>
                    )}
                  </div>
                  {this.state.active === 3 ? (
                    <span className="fs-11">
                      {tip}
                      {currency}{" "}
                    </span>
                  ) : (
                    <span className="total ml-1">20%</span>
                  )}
                </MDBRow>
              </MDBCard>
            </MDBRow>
          )}

          {!this.state.showInput ? (
            <MDBRow className="mt-2" center>
              <MDBCard
                className={`${
                  i18n.language === "en"
                    ? " card-other ml-1"
                    : "card-other mr-1 left-to-right"
                }`}
                onClick={() => this.removePercentage()}
              >
                <MDBRow className="d-flex justify-content-center">
                  <span
                    className="total ml-1"
                    onClick={() => {
                      this.toggleShowInput();
                      this.setState({ tip: null }, () =>
                        this.getTotalWithoutTip()
                      );
                    }}
                  >
                    {t("Other")}
                  </span>
                </MDBRow>
              </MDBCard>
            </MDBRow>
          ) : (
            <MDBRow className="mt-2" center>
              <MDBCard
                className={
                  this.state.showInput
                    ? `card-other-active overflow-hidden ${
                        i18n.language === "en"
                          ? " ml-1 "
                          : " mr-1 left-to-right"
                      }`
                    : ` card-other overflow-hidden ${
                        i18n.language === "en"
                          ? "ml-1  "
                          : "mr-1  left-to-right"
                      }`
                }
                onClick={() => this.removePercentage()}
              >
                <MDBRow className="d-flex justify-content-center">
                  <MDBInput
                    className="input-card"
                    value={other}
                    ref={this.inputRef}
                    pattern="[0-9]*"
                    type="number"
                    id="inputRef"
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      // Determine the maximum length based on currency
                      const maxLength =
                        currency === "USD" ? 3 : currency === "LBP" ? 8 : 3;

                      // Keep only the first 'maxLength' digits
                      const validInput = inputValue
                        .replace(/\D/g, "")
                        .slice(0, maxLength);

                      this.setState(
                        {
                          other: validInput,
                          tip: validInput, // Update tip with the same value
                        },
                        () => this.updateAmount()
                      );
                    }}
                  ></MDBInput>
                  <span className="fs-13">
                    {currency === "USD" ? "USD" : currency}
                  </span>
                </MDBRow>
              </MDBCard>
            </MDBRow>
          )}
          <MDBRow center>
            <img src={Check} alt="Check" />
          </MDBRow>
          <MDBRow style={{ marginTop: "-20px", marginBottom: "60px" }} center>
            <span className="term-text">
              {t("By using Q-Check you accept our")}
            </span>
            <span className="term-word">{t("Terms & Conditions")}</span>
          </MDBRow>

          <MDBCard className="split-the-bill-pay-btn-card">
            <div className="split-centered-div">
              <MDBContainer>
                <MDBRow className=" mt-2">
                  <MDBCol size="8" className="align-items-start d-flex" middle>
                    <span>
                      <span className="your-share ">{t("Your Share")}</span>
                      <span className="includingTax">
                        {t("(Including Tax)")}
                      </span>
                    </span>
                  </MDBCol>
                  <MDBCol size="4" middle>
                    <span
                      className={`${
                        i18n.language === "en"
                          ? "  float-right tipWithVATValue "
                          : " left-to-right float-left tipWithVATValue"
                      }`}
                    >
                      {dataInfo?.currency === "USD" ? "$" : dataInfo?.currency}{" "}
                      {this.roundNumber(yourShare)}
                    </span>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol size="6" className="align-items-start d-flex" middle>
                    <span className="tipAmount">{t("Your Tip Amount")}</span>
                  </MDBCol>
                  <MDBCol size="6" middle>
                    <span
                      className={`${
                        i18n.language === "en"
                          ? " float-right tipValue "
                          : " left-to-right   float-left tipValue"
                      }`}
                    >
                      {currency === "USD" ? "$" : currency} {tip ? tip : 0}
                    </span>
                  </MDBCol>
                  <hr className="dotted-line"></hr>
                </MDBRow>
                <MDBRow style={{ margin: "11px 0px" }}>
                  <MDBCol size="6" className="align-items-start " middle>
                    <MDBRow md="12">
                      <span className="paying">{t("You're Paying")}</span>
                    </MDBRow>
                    <MDBRow md="12">
                      <span
                        className={`${
                          i18n.language === "en"
                            ? " blue-desc "
                            : " left-to-right  blue-desc"
                        }`}
                      >
                        {dataInfo?.currency === "USD"
                          ? "$"
                          : dataInfo?.currency}{" "}
                        {this.roundNumber(amountToPay)}
                      </span>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol size="6">
                    <MDBBtn
                      className="pay-now-btn-split"
                      disabled={disabled}
                      // onClick={() => this.payNow()}
                      onClick={() => this.toggleExeededAmount()}
                    >
                      {loading ? (
                        <MDBIcon icon="spinner" className="fs-13" spin />
                      ) : (
                        t("Pay Now")
                      )}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </MDBCard>
        </section>
        <MDBModal
          isOpen={this.state.exceededAmount}
          toggle={this.toggleExeededAmount}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <span className="split-modal-text">
                {t("The amount you are paying")}
              </span>
              <span className="split-modal-text">
                {t("has exceeded the bill!!")}
              </span>
            </MDBRow>
            <MDBRow center>
              <img src={moneyPaper} alt="Money paper" className="money-paper" />
            </MDBRow>
            <MDBRow center className="mt-3 d-flex">
              <p className="exceeded-amount">{t("Exceeded Amount")}</p>
            </MDBRow>
            <MDBRow center>
              <p className="exceeded-value">
                {currency === "USD" ? "$" : currency}
                {this.roundNumber(parseFloat(this.state.amountExceeded))}
              </p>
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn
                className="white-with-blue-border-btn"
                disabled={noTipDisabled}
                onClick={() => this.paynowSplit("noTip")}
              >
                {noTipLoading ? (
                  <MDBIcon icon="spinner" className="fs-13" spin />
                ) : (
                  t("Pay The exact amount")
                )}
              </MDBBtn>
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn
                className="blue-btn"
                disabled={tipDisabled}
                onClick={() => this.paynowSplit("withTip")}
              >
                {loading ? (
                  <MDBIcon icon="spinner" className="fs-13" spin />
                ) : (
                  t("Tip The Rest")
                )}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.payingTheBillModal}
          toggle={this.togglePayingTheBillModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <p className="modal-text">{t(message)}</p>
            </MDBRow>
            <MDBRow center>
              <img src={moneyPaper} alt="Money paper" className="money-paper" />
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn
                className="blue-btn"
                onClick={this.togglePayingTheBillModal}
              >
                {t("Done")}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.splittingModal}
          toggle={this.toggleSplittingTheModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <p className="modal-title">{t("Splitting The Bill")}</p>
              <p className="modal-text mb-0">{t(message)} </p>
            </MDBRow>
            <MDBRow center>
              <img alt={t("Splitting")} className="spliting" src={Splitting} />
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn
                className="try-again-btn"
                onClick={this.toggleSplittingTheModal}
              >
                {t("Try Again")}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.refreshModal}
          toggle={this.toggleRefreshModal}
          size="sm"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow center className="mt-2">
              <p
                className={`modal-text ${
                  i18n.language === "en" ? "" : "right-to-left"
                }`}
              >
                {t("Your bill is updated, Kindly")}
                <br></br>
                {t("review your order before")}
                <br></br> {t("proceeding to pay")}
              </p>
            </MDBRow>
            <MDBRow center>
              <img src={invoiceBill} alt="invoice paper" />
            </MDBRow>
            <MDBRow center className="mt-3 mb-3">
              <MDBBtn
                className="blue-btn"
                onClick={() => this.updateAmountLeftToPay()}
              >
                {t("Proceed")}
              </MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          isOpen={this.state.underDevelopmentModal}
          toggle={this.toggleUnderDevelopment}
          size="md"
          className="modals"
          centered
        >
          <MDBModalBody>
            <MDBRow className="mt-2">
              <MDBCol>
                <p className="modal-text mb-0 text-initial">
                  {t("This feature will be available soon.")}
                </p>
                <p className="modal-text mb-0 text-initial">
                  {t(" Thank you for your patience!")}
                </p>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication ? state.authentication.token : "",
  coreMerchantValue: state?.mediaDirectory
    ? state?.mediaDirectory?.coreMerchantValue
    : "",
  accessToken: state?.auth ? state?.auth?.accessToken : "",
  refreshToken: state?.auth ? state?.auth?.refreshToken : "",
  backgroundLogo: state?.mediaDirectory
    ? state?.mediaDirectory?.backgroundLogo
    : "",
  logoURL: state?.mediaDirectory ? state?.mediaDirectory?.logoURL : "",
  orderId: state?.mediaDirectory ? state?.mediaDirectory?.orderId : "",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTokens: (aceessToken, refreshToken) =>
    dispatch(setTokens(aceessToken, refreshToken)),
  setCoreMerchantValue: (coreMerchantValue) =>
    dispatch(setCoreMerchantValue(coreMerchantValue)),
  setImageUrls: (backgroundLogo, logoURL) =>
    dispatch(setImageUrls(backgroundLogo, logoURL)),
  setOrderId: (orderId) => dispatch(setOrderId(orderId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation("translations")(SplitTheBill))
);
