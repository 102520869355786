import React, { Suspense } from 'react';

import './App.scss'
import i18n from './i18n-config/i18n';
import Routes from './Routing/RoutesConfig'; 

function App() {
  return (
    <Suspense fallback='null'>

      <div className="App">
        <header className={`App-header ${i18n.language==="ar" && "arabic-parent-styling"}`}>

          <main>
            <Routes />
          </main>

        </header>
      </div>
    </Suspense>
  );
}

export default App;
