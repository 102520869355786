import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import { BrowserRouter } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";

// redux related imports
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

//redux-persist package
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react";
// my own middleware
import { onRehydrationMiddleware } from "./Redux/Middlewares/RehydrationMiddleware";

// import registerServiceWorker from './serviceWorker';
import rootReducer from "./Redux/Reducers/index";

// configuration for the Redux-Persist dependency

// ReactDOM.render( <App />,   document.getElementById('root'));
// );
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["form"], // blacklist means that I don't want certain objects of the state to be persisted
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, onRehydrationMiddleware))
);
// engulfing our entire store with the persistor functionality
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
