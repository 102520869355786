import { MDBCard, MDBCol, MDBRow } from "mdbreact";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Check from "../../assets/home/check-bottom.png";
import i18n from "../../i18n-config/i18n";
import { setTokens } from "../../Redux/Actions/authActions";
import {
  setCoreMerchantValue,
  setImageUrls,
  setOrderId,
} from "../../Redux/Actions/mediaDirectoryActions";

const queryString = window.location.search;

const urlParams = new URLSearchParams(queryString);

class FailedPage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isMobile: window.innerWidth <= 435,
      payBill: false,
      recordGuid: urlParams.get("RecordGuid")
        ? urlParams.get("RecordGuid")
        : "",
      token: "",
    };
    // this.inputRef = React.createRef();
  }
  handleTabletResize = () => {
    this.setState({ isTablet: window.innerWidth <= 875 });
  };
  handleMobileResize = () => {
    this.setState({ isMobile: window.innerWidth <= 426 });
  };
  componentDidMount() {
    this.updateIsMobileState(); // Initial detection
    window.addEventListener("resize", this.handleResize); // Add event listener

    this.tabletMedia = window.innerWidth <= 768;
    this.mobileMedia = window.innerWidth <= 426;
    this.handleTabletResize();
    this.handleMobileResize();
    window.addEventListener("resize", this.handleTabletResize);
    window.addEventListener("resize", this.handleMobileResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleTabletResize);
    window.removeEventListener("resize", this.handleMobileResize);
  }

  handleResize = () => {
    this.updateIsMobileState(); // Call method to update isMobile state
  };

  updateIsMobileState = () => {
    const isMobile = window.innerWidth <= 435; // Adjust the width breakpoint as needed
    this.setState({ isMobile });
  };

  render() {
    const { t, backgroundLogo, logoURL } = this.props;

    return (
      <>
        {!this.state.payBill ? (
          <>
            <section id="homepage">
              <section id="home" className="section">
                {/* <MDBContainer className="container-img"> */}
                {/* <MDBCard > */}
                <img
                  src={backgroundLogo}
                  width="400px"
                  height="250px"
                  className={`smiley-bg ${
                    i18n.language === "en"
                      ? " align-items-end"
                      : " align-items-start"
                  }`}
                  alt="background logo"
                ></img>

                <MDBRow center>
                  <MDBCard className="logo-card">
                    <img
                      src={logoURL}
                      width="178px"
                      height="178px"
                      className="taste-img"
                      alt="branch logo"
                    />
                  </MDBCard>
                </MDBRow>
                <>
                  <MDBRow
                    center
                    className={`${
                      i18n.language === "en"
                        ? " left-to-right mt-5"
                        : " right-to-left  mt-5"
                    }`}
                  >
                    <span className="payment-completed">
                      {t("Payment Failed !")}
                    </span>
                  </MDBRow>

                  <MDBRow
                    center
                    className={`${
                      i18n.language === "en"
                        ? " left-to-right "
                        : " right-to-left"
                    }`}
                  >
                    <span className="success-desc">
                      {" "}
                      {t("Please try again")}
                    </span>
                  </MDBRow>
                  <MDBRow className="success-inner-row mt-5" center>
                    <MDBCol size="12" className="qcheck-logo-col">
                      <img
                        src={Check}
                        className="qcheck-logo"
                        alt="qcheck-logo"
                      />
                    </MDBCol>
                  </MDBRow>
                </>
                {/* </MDBContainer> */}
              </section>
            </section>
          </>
        ) : (
          //
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication ? state.authentication.token : "",
  coreMerchantValue: state?.mediaDirectory
    ? state?.mediaDirectory?.coreMerchantValue
    : "",
  accessToken: state?.auth ? state?.auth?.accessToken : "",
  refreshToken: state?.auth ? state?.auth?.refreshToken : "",
  backgroundLogo: state?.mediaDirectory
    ? state?.mediaDirectory?.backgroundLogo
    : "",
  logoURL: state?.mediaDirectory ? state?.mediaDirectory?.logoURL : "",
  orderId: state?.mediaDirectory ? state?.mediaDirectory?.orderId : "",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setTokens: (aceessToken, refreshToken) =>
    dispatch(setTokens(aceessToken, refreshToken)),
  setCoreMerchantValue: (coreMerchantValue) =>
    dispatch(setCoreMerchantValue(coreMerchantValue)),
  setImageUrls: (backgroundLogo, logoURL) =>
    dispatch(setImageUrls(backgroundLogo, logoURL)),
  setOrderId: (orderId) => dispatch(setOrderId(orderId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation("translations")(FailedPage))
);
