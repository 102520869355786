import FailedPage from "../Containers/Home/FailedPage";
import HomePage from "../Containers/Home/HomePage";
import SuccessPage from "../Containers/Home/SuccessPage";

export const routesWithoutAuthValidation = [
  {
    path: "/",
    component: HomePage,
    key: "/",
    exact: true,
  },
  {
    path: "/failed:externalReferenceId?",
    component: FailedPage,
    key: "/failed:externalReferenceId?",
    exact: true,
  },
  {
    path: "/success:externalReferenceId?",
    component: SuccessPage,
    key: "/success:externalReferenceId?",
    exact: true,
  },
];
