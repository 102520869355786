import { refresher_Initialization } from "../Actions/index";

let hasRehydrated = false;

export const onRehydrationMiddleware = (store) => (next) => (action) => {
  // if (!hasRehydrated && action.type === 'persist/REHYDRATE') {
  //     hasRehydrated = true;
  //     next(action)
  //     store.dispatch(refresher_Initialization());
  // } else {
  next(action);
  // }
};
