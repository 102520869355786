import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authentication from "./auth";
import mediaDirectoryReducer from "./mediaDirectoryReducer";
import authReducer from "./authReducer";

export default combineReducers({
  authentication,
  mediaDirectory: mediaDirectoryReducer,
  auth: authReducer,
  form: formReducer,
});
